import * as microsoftTeams from '@microsoft/teams-js';

export const captureImage = async (): Promise<File | undefined> => {
  // Method to ask for image capture permission and then select media
  const imageProp: microsoftTeams.media.ImageProps = {
    sources: [microsoftTeams.media.Source.Camera, microsoftTeams.media.Source.Gallery],
    startMode: microsoftTeams.media.CameraStartMode.Photo,
    ink: true,
    cameraSwitcher: false,
    textSticker: true,
    enableFilter: true,
  };

  const mediaInput: microsoftTeams.media.MediaInputs = {
    mediaType: microsoftTeams.media.MediaType.Image,
    maxMediaCount: 1,
    imageProps: imageProp,
  };

  return new Promise((resolve, reject) => {
    microsoftTeams.media.selectMedia(mediaInput, (error: microsoftTeams.SdkError, attachments: microsoftTeams.media.Media[]) => {
      if (error) {
        reject(error);
      }

      if (attachments && attachments.length > 0) {
        const firstAttachment = attachments[0];

        firstAttachment.getMedia((error: microsoftTeams.SdkError, blob: Blob) => {
          if (error) {
            reject(error);
          }

          if (blob) {
            const file = new File([blob], 'image.jpg', { type: blob.type });
            resolve(file);
            // resolve(blob);
          }
        });
      }
    });
  });
};
