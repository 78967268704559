import { logger } from "../Logger";
import { Constants } from "../components/common/Constants";
import { IGetInitialLovRequestData, ILovInputType, IValidateLOVSelectionValues } from "../components/common/TcSOATypes";
import { OperationName } from "../types/CommonTypes";
import { TCInputType, TCInputTypeUtils } from "./TCInputTypeUtils";

/**@interface TCLOVType
 * @description Interface to represent Teamcenter LOV business type objects.
 * @member uid {string} uid of the LOV object.
 * @member type {string} type of the LOV object.
 * @member className {string} class name of the LOV object.
 */
export interface TCLOVType {
  uid: string,
  type: string,
  className: string,
}

/**@interface TCLOVDataValue
 * @description Interface to represent the value of a LOV type object.
 * @member uid {string} uid of the LOV value. Can be empty.
 * @member displayValue {string} display value of the LOV.
 * @member internalValue {string} intername of the LOV value.
 * @member dataType {number} type of the LOV value.
 */
export interface TCLOVDataValue {
  displayValue: string,
  internalValue: string,
  dataType: number,
  uid: string,
}

/**@interface TCLOVDataType
 * @description Interface to represent the LOV input type object.
 * @member lovName {string} name of the LOV input field.
 * @member lovDisplayName {string} display name of the LOV field.
 * @member isDependentLov {boolean} display name of the LOV field.
 * @member lovDataValue {ITCLOVDataValue} all values of the LOV field.
 * @member selectedValue {string} selected value of the LOV field.
 * @member childLovName {string} {optional} name of the child LOV name if LOV is an inter-dependent LOV.
 * @member parentLovValue {string} {optional} value of the parent LOV value if LOV is an inter-dependent LOV.
 * @member filterString {string} value of filter string for the LOV type to filter the LOV values.
 * @member isValueSelected {boolean} boolean value to denote if value is selected or not for the LOV.
 * @member lovData {object} {optional} stores the response of getInitial/getNextLov response.
 * @member moreValuesExist {boolean} {optional} from the response stores more values exists for Lov.
 */
export interface TCLOVDataType {
  lovName: string,
  internalLovName: string,
  lovDisplayName: string,
  isDependentLov: boolean;
  lovDataValue: ITCLOVDataValue,
  selectedValue: string,
  childLovName?: string;
  parentLovValue?: string;
  filterString: string,
  isValueSelected: boolean,
  lovData?:object,
  moreValuesExist?:boolean
}

export type ITCLOVDataValue = TCLOVDataValue[] | undefined;
export type ITCLOVDataType = TCLOVDataType | undefined;

/**@class TCLOVTypeUtils
 * @description Utility class for handling all LOV related operations and scenarios.
 * @method prepareGetInitialLovRequestData method to prepare the request data for get initial API call.
 * @method populateTCLOVDataType method to populate the LOV input type from all input fields.
 * @method extractLovValues method to extract all LOV values for given LOV field.
 */
export class TCLOVTypeUtils {

  /**@function prepareGetInitialLovRequestData
   * @description method to prepare the request data for get initial LOV API call.
   * @param tcLovType {TCLOVDataType} input LOV field type object.
   * @param tcInputFields {TCInputType[]} all input fields objects.
   * @param opName {OperationName} operation Name.
   * @param modifyProps {string[]} list of modified properties.
   * @returns IGetInitialLovRequestData request data for calling the get Initial LOV API.
   */
  public static prepareGetInitialLovRequestData(tcLovType: TCLOVDataType, tcInputFields: TCInputType[], opName: OperationName, modifyProps: string[]): IGetInitialLovRequestData {
    logger.logTrace(`Entered ${this.prepareGetInitialLovRequestData.name}`);
    let requestData: IGetInitialLovRequestData;
    if (tcLovType) {
      const lovInputType: ILovInputType = TCLOVTypeUtils.prepareLovInput(tcLovType, tcInputFields, opName, modifyProps);
      if (lovInputType) {
        requestData = {
          propertyName: TCInputTypeUtils.getPropertyName(tcLovType.lovName),
          lov: Constants.dummyBusinessObject,
          lovInput: lovInputType,
          filterData: {
            filterString: tcLovType.filterString,
            maxResults: 2000,
            numberToReturn: 25,
            order: 1,
            sortPropertyName: ""
          }
        };
      }
    }
    logger.logTrace(`Exit ${this.prepareGetInitialLovRequestData.name}`);
    return requestData;
  }

  /**@function prepareGetNextLovRequestData
   * @description method to prepare the request data for get next LOV API call.
   * @param tcLovType {TCLOVDataType} input LOV field type object.
   * @param tcInputFields {TCInputType[]} all input fields objects.
   * @returns request data for calling the get next LOV API.
   */
  public static prepareGetNextLovRequestData(tcLovType: TCLOVDataType, tcInputFields: TCInputType[]): any {
    logger.logTrace(`Entered ${this.prepareGetNextLovRequestData.name}`);
    let requestData: any;
    if (tcLovType.moreValuesExist) {
      const lovType: TCInputType[] = tcInputFields.filter((tcInputType) => tcInputType.propertyName === tcLovType.lovName);
      if (lovType && lovType.length === 1) {
        requestData = tcLovType.lovData;
      }
    }
    logger.logTrace(`Exit ${this.prepareGetNextLovRequestData.name}`);
    return requestData;
  }

  /**@function prepareLovInput
   * @description method to prepare the LOVInput for LOV API calls.
   * @param tcLovType {TCLOVDataType} 
   * @param tcInputFields {TCInputType[]}
   * @param opName {OperationName} all input fields objects.
   * @param tcInputFields {string[]} all input fields objects.
   * @returns {ILovInputType} prepared LOVInputType or undefined.
   */
  private static prepareLovInput(tcLovType: TCLOVDataType, tcInputFields: TCInputType[], opName: OperationName, modifyProps: string[]): ILovInputType {
    logger.logTrace(`Entered ${this.prepareLovInput.name}`);
    let lovInputType: ILovInputType;
    const lovType: TCInputType[] = tcInputFields.filter((tcInputType) => tcInputType.propertyName === tcLovType.lovName);
    const objectType: string = TCInputTypeUtils.getBusinessObjectType(lovType[0].srcObjectTypeName!);
    const objectUId: string = TCInputTypeUtils.getBusinessObjectUidFromTCType(lovType[0]);
    if (lovType && lovType.length === 1) {
      lovInputType = {
        boName: objectType,
        operationName: opName,
        owningObject: {
          type: objectType,
          uid: objectUId,
          ObjectId: "",
          ClassName: ""
        },
        propertyValues: TCLOVTypeUtils.getPropertyValueForGetInitialCall(modifyProps, tcInputFields)
      };
    }
    logger.logTrace(`Exit ${this.prepareLovInput.name}`);
    return lovInputType;
  }

  /**@function prepareValidateLOVSelectionRequestData
   * @description method to prepare request data for validate LOV selection API call.
   * @param tcLovType {TCLOVDataType} input LOV field type object.
   * @param tcInputFields {TCInputType[]} all input fields objects.
   * @param opName {OperationName} operation Name.
   * @param updatedFields {string[]} list of modified properties.
   * @returns IValidateLOVSelectionValues request data for calling validate LOV selection API.
   */
  public static prepareValidateLOVSelectionRequestData(tcLovType: TCLOVDataType, tcInputFields: TCInputType[], opName: OperationName, updatedFields: string[]): IValidateLOVSelectionValues{
    logger.logTrace(`Entered ${this.prepareValidateLOVSelectionRequestData.name}`);
    const lovInputType: ILovInputType = TCLOVTypeUtils.prepareLovInput(tcLovType, tcInputFields, opName, updatedFields);
    const requestData: IValidateLOVSelectionValues = {
      lovInput: lovInputType,
      propName: TCInputTypeUtils.getPropertyName(tcLovType.lovName),
      uidOfSelectedRows: []
    };
    logger.logTrace(`Exit ${this.prepareValidateLOVSelectionRequestData.name}`);
    return requestData;
  }

  private static getPropertyValueForGetInitialCall(modifyProps: string[], tcInputTypes: TCInputType[]): any {
    logger.logTrace(`Entered ${this.getPropertyValueForGetInitialCall.name}`);
    const propObject: any = {};
    for (const propName of modifyProps) {
      const tcType: TCInputType | undefined = tcInputTypes.find(x => TCInputTypeUtils.getBasePropertyName(x.propertyName) === TCInputTypeUtils.getBasePropertyName(propName));
      if (tcType) {
        propObject[propName] = [tcType.dbValues? tcType.dbValues.toString() : ''];
      }
    }
    logger.logTrace(`Exit ${this.getPropertyValueForGetInitialCall.name}`);
    return propObject;
  }

  /**@function populateTCLOVDataType
   * @description method to populate the LOV input type from all input fields.
   * @param tcInputTypes all Teamcenter input type fields.
   * @returns map of all LOV type input fields.
   */
  public static populateTCLOVDataType(tcInputTypes: TCInputType[]): any {
    logger.logTrace(`Entered ${this.populateTCLOVDataType.name}`);
    const mapLovDataType: any = {};
    if (tcInputTypes) {
      for (const tcType of tcInputTypes) {
        if (tcType.hasLOV) {
          const lovType: TCLOVDataType = {
            lovName: tcType.propertyName,
            internalLovName: TCInputTypeUtils.getBasePropertyName(tcType.propertyName),
            lovDisplayName: tcType.propertyDisplayName,
            isDependentLov: false,
            lovDataValue: undefined,
            selectedValue: tcType.value,
            filterString: tcType.value,
            isValueSelected: tcType.value ? true : false
          };
          mapLovDataType[tcType.propertyName] = lovType;
        }
      }
    }
    logger.logTrace(`Exit ${this.populateTCLOVDataType.name}`);
    return mapLovDataType;
  }

  /**@function findLOVUsingInternalName
   * @description method to find LOV type using the internal name of the LOV.
   * @param internalName {string} internal name of the LOV type.
   * @param lovFields {any} list of all LOV fields.
   * @returns {ITCLOVDataType} TCLOVDataType if found else undefined.
   */
  public static findLOVUsingInternalName(internalName: string, lovFields: any): ITCLOVDataType {
    logger.logTrace(`Entered ${this.findLOVUsingInternalName.name}`);
    let retVal: ITCLOVDataType;
    for (const key in lovFields) {
      const element: TCLOVDataType = lovFields[key] as TCLOVDataType;
      if (element && element.internalLovName === internalName) {
        retVal = element;
        break;
      }  
    }
    logger.logTrace(`Exit ${this.findLOVUsingInternalName.name}`);
    return retVal;
  }

  /**@function extractLovValues
   * @description method to extract the LOV values for a given LOV type field.
   * @param lovValues response having all possible LOV valued for the LOV field.
   * @param tcLovType input LOV field type object.
   * @param appendLovValues {optional} boolean value to append the new extracted value.
   */
  public static extractLovValues(lovResponse: any, tcLovType: TCLOVDataType, appendLovValues?:boolean): string[] {
    logger.logTrace(`Entered ${this.extractLovValues.name}`);
    let retArray: string [] = [];
    const lovDatas: TCLOVDataValue[] = [];
    // Checking if the LOV is an inter-dependent LOV
    if (lovResponse.behaviorData && lovResponse.behaviorData.style === Constants.LOVInterdependent) {
      tcLovType.isDependentLov = true;
      const dependendProps: string[] = lovResponse.behaviorData.dependendProps;
      if (dependendProps.length > 1) {
        retArray = dependendProps;
        for (let index = 0; index < dependendProps.length; index++) {
          const propName: string = tcLovType.internalLovName;
          if (dependendProps[index] === propName) {
            if (!(tcLovType.childLovName && tcLovType.childLovName === dependendProps[index + 1])) {
              tcLovType.childLovName = dependendProps[index + 1];
            }
          }
        }
      }
    }

    // Populating the LOV values.
    if (lovResponse.lovValues) {
      for (const lovValue of lovResponse.lovValues) {
        const lovValueKey: string = TCLOVTypeUtils.getLOVValueKeyString(lovValue.propInternalValueTypes);
        const lovData: TCLOVDataValue = {
          dataType: lovValue.propInternalValueTypes[lovValueKey],
          displayValue: lovValue.propDisplayValues[lovValueKey][0],
          internalValue: lovValue.propInternalValues[lovValueKey][0],
          uid: lovValue.uid,
        };
        lovDatas.push(lovData);
      }
    }

    if (lovDatas.length === 0) {
      logger.logError(`Failed to extract the LOV values for ${tcLovType.lovDisplayName}`);
    }
    if ( appendLovValues && tcLovType.lovDataValue && tcLovType.lovDataValue.length > 0) {
      tcLovType.lovDataValue = [...tcLovType.lovDataValue, ...lovDatas];
    } else {
      tcLovType.lovDataValue = lovDatas;
    }
    tcLovType.moreValuesExist = lovResponse.moreValuesExist;
    if (lovResponse.lovData) {
      tcLovType.lovData = lovResponse.lovData;
    }
    logger.logTrace(`Exit ${this.extractLovValues.name}`);
    return retArray;
  }

  /**@interface getLOVValueKeyString
   * @description method to get the LOV value key to extract the LOV values.
   * @param lovDataType {any} datatype value of the LOV field object.
   * @returns {string} value key for extracting the LOV values.
   */
  private static getLOVValueKeyString(lovDataType: any): string {
    logger.logTrace(`Entered ${this.getLOVValueKeyString.name}`);
    let retStr = '';
    if (lovDataType) {
      const keys: string[] = Object.keys(lovDataType);
      if (keys && keys.length === 1) {
        retStr = keys[0];
      }
    }

    logger.logTrace(`Exit ${this.getLOVValueKeyString.name}`);
    return retStr;
  }

  /**@function getSelectedLovValue
   * @description method to get the internal name of the lov type for selected option.
   * @param selectedValue {string} selected display value of the LOV.
   * @param lovType {TCLOVDataType} input lov type.
   * @returns {string} returns intenal value for the selected lov value.
   */
  public static getSelectedLovValue(selectedValue: string, lovType: TCLOVDataType) {
    logger.logTrace(`Entered ${this.getSelectedLovValue.name}`);
    let retStr = '';
    if (selectedValue) {
      if (lovType.lovDataValue) {
        for (const lovValue of lovType.lovDataValue) {
          if (lovValue.displayValue === selectedValue) {
              retStr = lovValue.internalValue;
             break;
          }
        }
      }
    }
    logger.logTrace(`Exit ${this.getSelectedLovValue.name}`);
    return retStr;
  }
}
