import React, { useEffect, useContext, useState } from "react";
import { TeamsFxContext } from "../Context";
import { useGetLoginUrl } from "../../hooks/useGetLoginUrl";

// The popup used to login to Teamcenter
const TeamcenterAuthPopup: React.FunctionComponent = () => {

  const teamsContext = useContext(TeamsFxContext);

  const [userAadOid, setUserAadOid] = useState<string>('');

  teamsContext?.teamsUserCredential?.getUserInfo().then((userInfo: any) => {
    setUserAadOid(userInfo?.objectId);
  });

  const {isFetched, url} = useGetLoginUrl(userAadOid);

  useEffect(() => {
    if (url) {
      window.location.assign(url);
    }
  }, [isFetched, url]);

  return <></>;
};

export default TeamcenterAuthPopup;
