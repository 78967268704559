import config from "../config/config";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { useApi } from "./useApi";
import { logger } from "../Logger";
import { useLocation } from "react-router-dom";
import { UseTypeQueryResult } from '../types';
import { MethodType } from "../types/ApiRequest";

/**
 * Get login URL
 */
export const useGetLoginUrl = (userAadOid: string): UseTypeQueryResult<{ url: string }> => {
  const teamsContext = useContext(TeamsFxContext);
  const search = useLocation().search;

  const sessionId = new URLSearchParams(search).get("sessionId");
  const oldSessionId = teamsContext.teamcenter.session?.sessionId || "";
  const userUid = teamsContext.teamcenter.session?.userUid || "";
  const clientId = config.clientId;

  const url = `${
    config.tcTeamsWebApiUrl
  }/auth/login/${sessionId}/${clientId}?authEndUri=${encodeURI(
    "auth-tc-end.html"
  )}&sessionId=${oldSessionId}&userUid=${userUid}&userAadOid=${userAadOid}`;

  // As with Edge the value teamsContext.teamcenter.isAuthenticated is true even when the user is disconnected,
  // Instead of using the line below to enable the login  request
  // !teamsContext.teamcenter.isAuthenticated && userAadOid !== ''
  // we will use the one below
  // (!teamsContext.teamcenter.isAuthenticated || (window.navigator.userAgent.indexOf("Edg") !== -1)) && userAadOid !== ''
  // TODO: As it's more a turnaround than a fix, a new bug (#2549) has been created to identify the origin of the issue with Edge.
  // https://dev.azure.com/msft-siemens/Teamcenter-Teams/_workitems/edit/1663/?workitem=2549
  const response = useApi({
    url: url,
    method: MethodType.GET,
    key: "login",
    enabled: (!teamsContext.teamcenter.isAuthenticated || (window.navigator.userAgent.indexOf("Edg") !== -1)) && userAadOid !== '',
    action: 'useGetLoginUrl'
  }, logger);

  return {
    get url() {
      return response?.data?.redirectUri;
    },
    ...response,
  };
};
