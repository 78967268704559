import {
  Button,
  DialogActions,
  DialogBody,
  DialogContent,
  Input,
  Label,
  Textarea,
  Checkbox,
  Spinner,
  InputOnChangeData,
  CheckboxOnChangeData,
  Combobox,
  ComboboxOpenChangeData
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import * as React from "react";
import _ from 'lodash';
import { useParams } from 'react-router';
import {
  TCDataTypes,
  TCInputType,
  TCInputTypeUtils,
} from "../../services/TCInputTypeUtils";
import { dialog } from "@microsoft/teams-js";
import { IGetDeclarativeStyleSheetsRequestData, ILoadViewModelForEditingRequestData,LoadViewModelForEditingRequestData, StyleSheetType, IViewModelProperty, ISaveObjectRequestData, SaveObjectRequestData, IGetInitialLovRequestData, ModelObject, ValidateLOVSelectionValues, IValidateLOVSelectionValues } from "../common/TcSOATypes";
import { logger } from "../../Logger";
import { AttachedItem, UseMutationTypeQuery } from "../../types";
import ProblemReportImage from "./ProblemReportImage";
import { useGetDeclarativeStyleSheet } from "../../hooks/useGetDeclarativeStyleSheet";
import { useLoadViewModelForEditing } from "../../hooks/useLoadViewModelForEditing";
import { useValidateLovValueSelections } from "../../hooks/useValidateLovValueSelections";
import { useSaveProblemReport } from "../../hooks/useSaveProblemReport";
import { ITCLOVDataType, TCLOVDataType, TCLOVTypeUtils } from "../../services/TcLovTypeUtils";
import { useGetInitialLov } from "../../hooks/useGetInitialLov";
import LovRenderer from "../common/LovRenderer";
import { TeamsFxContext } from "../Context";
import { OperationName } from "../../types/CommonTypes";
import * as microsoftTeams from '@microsoft/teams-js';
import { AnalyticsManager } from "../../services/analyticsUtility";
import { formatStringWithArgs } from "../../services/utility";
import AnalyticsConstants from "../common/AnalyticsConstants.json";
import { ListObjectUtils } from "../componentutils/ListObjectUtils";
import { Constants } from "../common/Constants";
import ParticipantsInfo from "./ParticipantsInfo";
import DateTime from '../common/widget/DateTime';
import { dateTimeToTcUTCDateTime, formatDateToTcString, currentTimeZoneDate, getCurrentTimeZoneDateForDatePicker } from "../../services/utility";


type ProblemDetailsParams = {
  uid: string;
  type: string;
  value: string;
};

const ProblemReportInfo: React.FunctionComponent = () => {

  // Logging problem reports info panel visit in analytics
  React.useEffect( () => {
    async function startProcessing() {
      if (teamsContext.configuration) { 
        const analyticsManager = AnalyticsManager.getInstance();
        analyticsManager.logPlace(formatStringWithArgs(AnalyticsConstants.panelInfoString, problemReport.value));
      }
    }
    startProcessing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initalize teams app and notify success of loading
  React.useEffect(() => {
    (async () => {
      await microsoftTeams.app.initialize();
      microsoftTeams.app.notifySuccess();
    })();
  }, []);

  const problemReport: any = useParams<ProblemDetailsParams>();

  // Use State Parameters
  const [tcInputFields, setInputFields] = React.useState<TCInputType[]>([]);
  const [tcLovFields, setLovFields] = React.useState<any>();
  const [modelObjects, setModelObjects] = React.useState<any>();
  const [inEditMode, setInEditMode] = React.useState<boolean>(false);
  const [updatedFields, setUpdatedFields] = React.useState<string[]>([]);
  const [isUpdatingPR, setUpdatingPR] = React.useState<boolean>(false);
  const [attachedItem, setAttachedItem] = React.useState<AttachedItem>({});
  const [problemImageTicket, setImageTicket] = React.useState<string>();
  const teamsContext = React.useContext(TeamsFxContext);
  const modifiedProps = React.useRef<string[]>([]);
  const [timePickerError, setTimePickerError] = React.useState<boolean>(false);

  const getLoadRequestData = () => {
    const requestData: ILoadViewModelForEditingRequestData = {
      object: {
        uid: problemReport.uid,
        type: problemReport.type,
        className: problemReport.type,
        objectId: problemReport.uid
      },
      propertyNames: getPropertyNames()
    };
    return [requestData];
  }

  const getPropertyNames = (): string[] => {
    const propertyNames: string[] = [];
    for (const property of tcInputFields) {
      propertyNames.push( property.propertyName);
    }
    return propertyNames;
  }

  const getUpdatedFieldsData = (): IViewModelProperty[] => {
    const viewModelProperties: IViewModelProperty[] = [];
    for(const key in updatedFields){
      const updatedProperty: TCInputType | undefined = tcInputFields.find((prop: { propertyName: string; }) => prop?.propertyName === updatedFields[key]);
      if (updatedProperty) {
        let dbValue: string = updatedProperty.value;
        if (updatedProperty.hasLOV) {
          dbValue = updatedProperty.dbValues!;
        }

        let intermediateObjectUids: string[] = []
        if ('intermediateObjectUids' in updatedProperty) {
          intermediateObjectUids = updatedProperty.intermediateObjectUids as string[];
        }
        const updatedField: IViewModelProperty = {
          dbValues: [dbValue],
          isModifiable: updatedProperty.isModifiable,
          intermediateObjectUids: intermediateObjectUids,
          propertyName: updatedProperty.propertyName,
          srcObjLsd: updatedProperty.srcObjLsd,
          uiValues: [updatedProperty.value]
        }
        viewModelProperties.push(updatedField);
      }
    }
    return viewModelProperties;
  }

  const getSaveObjectRequestData = (): SaveObjectRequestData[] => {
    const saveRequestData: ISaveObjectRequestData = {
      object: {
        uid: problemReport.uid,
        type: problemReport.type,
        className: problemReport.type,
        objectId: problemReport.uid
      },
      properties: getUpdatedFieldsData(),
      isPessimisticLock: false,
      workflowData: {}
    }
    return [saveRequestData];
  }

  const getCurrentEditProperty = (ev: React.ChangeEvent) => {
    for (let i = 0; i < tcInputFields.length; i++) {
      if(tcInputFields[i].propertyName === ev.target.id) {
        return tcInputFields[i];
      }
    }
  }

  const handleUpdateProblemReport = async() => {
    logger.logTrace(`Entered ${handleUpdateProblemReport.name}`);
    setUpdatingPR(true);
    const requestDataForSave: SaveObjectRequestData[] = getSaveObjectRequestData();
    // calling the backend API for saving objects
    if (requestDataForSave) {
      const response = await saveProblemReport.mutateAsync(requestDataForSave);
      setUpdatingPR(false);
      if(response.isError){
        logger.logError("Error:" + response.error);
      }
    } else {
      var label : string = "Failed to create request data for updating ";
      throw new Error(label +`${problemReport.value}`);
    }
    logger.logTrace(`Exit ${handleUpdateProblemReport.name}`);
  }

  const updateTcInputFieldsForUpdate = ( viewModel:any) =>{
    const viewModelJsonData: any = JSON.parse(viewModel);
    if (viewModelJsonData.objects && viewModelJsonData.objects.length>0 && viewModelJsonData.objects[0].props) {
      const widgetsData:any = viewModelJsonData.objects[0].props;
      for (let i = 0; i < tcInputFields.length; i++) {
        const propName: string = tcInputFields[i].propertyName;
        if (widgetsData[propName]) {
          if(widgetsData[propName].isModifiable) {
            tcInputFields[i].isModifiable = widgetsData[propName].isModifiable;
          }
          tcInputFields[i].srcObjLsd = widgetsData[propName].srcObjLsd;
        }
      }
    }
  };

  const handleEditProblemReport = async() => {
    logger.logTrace(`Entered ${handleEditProblemReport.name}`);
    const requestDataForEdit: LoadViewModelForEditingRequestData[] = getLoadRequestData();
    //Calling the Backend API for LoadingViewMOdelForEditing
    const response = await editProblemReport.mutateAsync(requestDataForEdit);
    if (response.isError) {
      logger.logError("Error:" + response.error);
    }
    if (response.editData) {
      updateTcInputFieldsForUpdate(response.editData);
    } else {
      logger.logError(`Failed to get Edit data.`);
    }
    logger.logTrace(`Exit ${handleEditProblemReport.name}`);
  };

  // API Mutations
  const editProblemReport: UseMutationTypeQuery<LoadViewModelForEditingRequestData[], any> = useLoadViewModelForEditing();
  const saveProblemReport: UseMutationTypeQuery<SaveObjectRequestData[], any> = useSaveProblemReport();
  const getStyleSheet: UseMutationTypeQuery<IGetDeclarativeStyleSheetsRequestData, any> = useGetDeclarativeStyleSheet();
  const getIntialLov: UseMutationTypeQuery<IGetInitialLovRequestData, any> = useGetInitialLov();
  const validateLOVSelectionAPI: UseMutationTypeQuery<ValidateLOVSelectionValues, any> = useValidateLovValueSelections();

  // Use Effect Hook

  React.useEffect( () => {
    async function startProcessing() {
      if (teamsContext.configuration) {
        const businessObject: ModelObject = {
          uid: problemReport.uid,
          type: problemReport.type,
          className: problemReport.type,
          objectId: problemReport.uid,
        };
        const requestData: IGetDeclarativeStyleSheetsRequestData =
          TCInputTypeUtils.getXRTRequestData(
            problemReport.type,
            StyleSheetType.INFO,
            businessObject
          );
        if (requestData) {
          const xrtResponse = await getStyleSheet.mutateAsync(requestData);
          if (xrtResponse) {
            const tcInputTypes: TCInputType[] =
              TCInputTypeUtils.extractTCInputTypes(
                xrtResponse.viewModel,
                xrtResponse.modelObject
              );
            setInputFields(tcInputTypes);
            const lovTypeMap: any =
              TCLOVTypeUtils.populateTCLOVDataType(tcInputTypes);
            setLovFields(lovTypeMap);
            const modelObjects = xrtResponse.modelObject;
            setModelObjects(modelObjects);
            const parentObject = modelObjects[problemReport.uid];
            const hasProblemItem = parentObject.props.CMHasProblemItem;
            const hasImpactedItem = parentObject.props.CMHasImpactedItem;
            if (hasProblemItem && hasProblemItem.dbValues) {
              const problemItemUid = hasProblemItem.dbValues[0];
              const problemItemObj = modelObjects[problemItemUid];
              if (problemItemObj) {
                const problemItem: AttachedItem = {
                  id: problemItemObj.props.item_id.uiValues[0],
                  name: problemItemObj.props.object_name.uiValues[0],
                  uid: problemItemObj.uid,
                  imageTicket:
                    problemItemObj.props.awp0ThumbnailImageTicket.dbValues[0],
                };
                setAttachedItem(problemItem);
              }
            }
            else if (hasImpactedItem && hasImpactedItem.dbValues) {
              const impactedItemUid = hasImpactedItem.dbValues[0];
              const impactedItemObj = modelObjects[impactedItemUid];
              if (impactedItemObj) {
                const impactedItem: AttachedItem = {
                  id: impactedItemObj.props.item_id.uiValues[0],
                  name: impactedItemObj.props.object_name.uiValues[0],
                  uid: impactedItemObj.uid,
                  imageTicket:
                  impactedItemObj.props.awp0ThumbnailImageTicket.dbValues[0],
                };
                setAttachedItem(impactedItem);
              }
            }
            const hasReferences = parentObject.props.CMReferences;
            if (hasReferences && hasReferences.dbValues) {
              const referenceUid = hasReferences.dbValues[0];
              const referenceObj = modelObjects[referenceUid];
              if (
                referenceObj &&
                referenceObj.props.awp0ThumbnailImageTicket &&
                referenceObj.props.awp0ThumbnailImageTicket.dbValues
              ) {
                setImageTicket(
                  referenceObj.props.awp0ThumbnailImageTicket.dbValues[0]
                );
              }
            }
          }
        }
      }
    }
    startProcessing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Form Action Handlers

  const isUpdateButtonDisabled = (): boolean => {
    if (updatedFields.length > 0 && !timePickerError) {
      return false;
    }
    return true;
  };

  const CloseProblemReport = () => {
    dialog.submit(undefined);
  };

  const handleEdit = async (ev: React.FormEvent) => {
    logger.logTrace(`Entered ${handleEdit.name}`);
    const analyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(formatStringWithArgs(AnalyticsConstants.cmdEditObject, problemReport.value));
    ev.preventDefault();
    setInEditMode(true);
    logger.logInformation('Action Edit Problem Report.');
    handleEditProblemReport();
    logger.logTrace(`Exit ${handleEdit.name}`);
  };

  const handleUpdate = (ev: React.FormEvent) => {
    logger.logTrace(`Entered ${handleUpdate.name}`);
    const analyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(formatStringWithArgs(AnalyticsConstants.actionUpdateObject, problemReport.value));
    ev.preventDefault();
    logger.logInformation('Action update Problem Report.');
    handleUpdateProblemReport();
    setInEditMode(false);
    setUpdatedFields([]);
    logger.logTrace(`Exit ${handleUpdate.name}`);
  };

  // Input Field Type Handlers/Updaters

  const handleStringPropertyChange = (ev: React.ChangeEvent, data: InputOnChangeData) => {
    const property: any = getCurrentEditProperty(ev);
    if (inEditMode && property.isModifiable) {
      logger.logTrace(`Entered ${handleStringPropertyChange.name}`);
      const updatedInputs = tcInputFields.map((input) => {
        if (input.propertyName === ev.target.id) {
          return {
            ...input,
            value: data.value,
          };
        }
        return input;
      });
      setInputFields(updatedInputs);
      const index: number = updatedFields.indexOf(ev.target.id);
      if (index === -1) {
        const newUpdatedFields = [...updatedFields, ev.target.id];
        setUpdatedFields(newUpdatedFields);
      }
      logger.logTrace(`Exit ${handleStringPropertyChange.name}`);
    }
  };

  const handleChangeBox = async (elementId: string, data: ComboboxOpenChangeData, event: any) => {
    logger.logTrace(`Entered ${handleChangeBox.name}`);
    const tcLovType: TCLOVDataType | undefined = tcLovFields[elementId];
    if (tcLovType) {
      if (data && data.open) {
        tcLovType.lovDataValue = undefined;
        callGetInitialLOVValues(elementId, "");
      } else if (!data.open) {
        if (!tcLovType.isValueSelected) {
          const updatedInputs = tcInputFields.map((input) => {
            if (input.propertyName === event.target.id) {
              return {
                ...input,
                value: tcLovType.selectedValue,
                dbValues: TCLOVTypeUtils.getSelectedLovValue(tcLovType.selectedValue, tcLovType),
              };
            }
            return input;
          });
          setInputFields(updatedInputs);
        }
        tcLovType.isValueSelected = tcLovType.selectedValue ? true : false;
      }
    }
    logger.logTrace(`Exit ${handleChangeBox.name}`);
  };

  // Method for calling getInitialLovValues on search string
  const onInputUpdate = (prop: TCInputType, event: any) => {
    logger.logTrace(`Entered ${onInputUpdate.name}`);
    prop.value = event.target.value.trim();
    callGetInitialLOVValues(prop.propertyName, event.target.value.trim());
    const tcLovType: TCLOVDataType | undefined = tcLovFields[prop.propertyName];
    if (tcLovType) {
      tcLovType.isValueSelected = false;
    }
    logger.logTrace(`Exit ${onInputUpdate.name}`);
  }

  // Method for calling getInitialLovValues
  const callGetInitialLOVValues = async (elementId: string, filterString: string) => {
    logger.logTrace(`Entered ${callGetInitialLOVValues.name}`);
    const tcLovType: TCLOVDataType | undefined = tcLovFields[elementId];
    if (tcLovType) {
      tcLovType.filterString = filterString;
      const requestData: IGetInitialLovRequestData = TCLOVTypeUtils.prepareGetInitialLovRequestData(tcLovType, tcInputFields, OperationName.EDIT, modifiedProps.current);
      if (requestData) {
        const response: any = await getIntialLov.mutateAsync(requestData);
        if (response && response.lovValues) {
          const dirtyProps: string[] = TCLOVTypeUtils.extractLovValues(response, tcLovType);
          if (dirtyProps.length > 0) {
            modifiedProps.current = _.union(dirtyProps, modifiedProps.current);
          }
        }
      }
    }
    logger.logTrace(`Exit ${callGetInitialLOVValues.name}`);
  };

  const clearDependentLOVFields = (dependentProp: string) => {
    logger.logTrace(`Entered ${clearDependentLOVFields.name}`);
    const lovType: ITCLOVDataType = TCLOVTypeUtils.findLOVUsingInternalName(dependentProp, tcLovFields);
    if (lovType) {
      lovType.isDependentLov = true;
      lovType.selectedValue = '';
      lovType.lovDataValue = undefined;
    }
    logger.logTrace(`Entered ${clearDependentLOVFields.name}`);
  };

  const handleTimePickerError = (errorExists:boolean) => {
    setTimePickerError(errorExists);
  };

  const isDateChanged = (inputDate: string, newDate: string): boolean => {
    return dateTimeToTcUTCDateTime(new Date(inputDate)) !== dateTimeToTcUTCDateTime(new Date(newDate));
  };

  const handleDatePropertyChange = (date: Date | null | undefined, id:string) => {
    logger.logTrace(`Entered ${handleDatePropertyChange.name}`);
    if (date) {
      const utcTime= dateTimeToTcUTCDateTime(date);
      let dateChanged = false;
      var UIdate = formatDateToTcString(date);
      const updatedInputs = tcInputFields.map((input) => {
        if (input.propertyName === id && isDateChanged(currentTimeZoneDate(input.dbValues), UIdate)) {
          dateChanged = true;
          return {
            ...input,
            value: utcTime,
            uiValues: UIdate,
            dbValues: utcTime,
          };
        }
        return input;
      });
      setInputFields(updatedInputs);
      if (dateChanged) {
        const newUpdatedFields = [...updatedFields, id];
        setUpdatedFields(newUpdatedFields);
      }
    }
    logger.logTrace(`Exit ${handleDatePropertyChange.name}`);
  };

  const handleDropDownValueChange = async (optionValue: string | undefined, tcLovType: TCLOVDataType) => {
    logger.logTrace(`Entered ${handleDropDownValueChange.name}`);
    if (optionValue) {
      const validateLOVRequest: IValidateLOVSelectionValues = TCLOVTypeUtils.prepareValidateLOVSelectionRequestData(tcLovType, tcInputFields, OperationName.EDIT, modifiedProps.current);
      if (validateLOVRequest) {
        const validateLOVResponse: any = await validateLOVSelectionAPI.mutateAsync(validateLOVRequest);
        if (validateLOVResponse && !validateLOVResponse.error) {
          const updateLovTypeList: any = {};
          if (validateLOVResponse.dependentPropNames && validateLOVResponse.dependentPropNames.length > 0) {
            const dependentProps: string[] = validateLOVResponse.dependentPropNames;
            for (const depProp of dependentProps) {
              clearDependentLOVFields(depProp);
              updateLovTypeList[depProp] = {key: depProp, dbValue: "", value: ""};
            }
          }
          if (validateLOVResponse.propHasValidValues) {
            tcLovType.selectedValue = optionValue;
            updateLovTypeList[tcLovType.internalLovName] = {key: tcLovType.internalLovName, dbValue: TCLOVTypeUtils.getSelectedLovValue(optionValue, tcLovType), value: tcLovType.selectedValue.toString()}
            const index: number = updatedFields.indexOf(tcLovType.lovName);
            if (index === -1) {
              const newUpdatedFields = [...updatedFields, tcLovType.lovName];
              setUpdatedFields(newUpdatedFields);
            }
            tcLovType.isValueSelected = true;
          }
          const updatedInputs: TCInputType[] = tcInputFields.map((input) => {
            const element = updateLovTypeList[TCInputTypeUtils.getBasePropertyName(input.propertyName)];
            if (element) {
              return {
                ...input,
                value: element.value,
                dbValues: element.dbValue,
              };
            }
            return input;
          });
          setInputFields(updatedInputs);
        } else {
          logger.logError(`Error: ${JSON.stringify(validateLOVResponse.error)}`);
        }
      }
    }
    logger.logTrace(`Exit ${handleDropDownValueChange.name}`);
  };

  const handleBooleanPropertyChange = (ev: React.ChangeEvent, data: CheckboxOnChangeData) => {
    const property: any = getCurrentEditProperty(ev);
    if (inEditMode === true && property.isModifiable) {
      logger.logTrace(`Entered ${handleBooleanPropertyChange.name}`);
      const updatedInputs = tcInputFields.map((input: TCInputType) => {
        if (input.propertyName === ev.target.id) {
          return {
            ...input,
            value: data.checked.toString(),
          };
        }
        return input;
      });
      setInputFields(updatedInputs);
      const newUpdatedFields = [...updatedFields, ev.target.id];
      setUpdatedFields(newUpdatedFields);
      logger.logTrace(`Exit ${handleBooleanPropertyChange.name}`);
    }
  };
  // UI Render Components

  const renderWidget = () => {
    logger.logTrace(`Entered ${renderWidget.name}`);
    const propList = tcInputFields.map((prop) => {
      const showAsLabel = !(inEditMode && prop.isModifiable);
      if (prop.type === TCDataTypes.String) {
        if (showAsLabel) {
          return(
          <Stack horizontal>
            <Label id={prop.propertyName} htmlFor={prop.propertyDisplayName} className='label-width'>
              {prop.propertyDisplayName}:
            </Label>
            <Label id={prop.propertyName}>
              {prop.value}
            </Label>
          </Stack>);
        }
        if (prop.hasLOV) {
          const tcLovType: TCLOVDataType = tcLovFields[prop.propertyName] as TCLOVDataType;
          return (
            <>
             <Label id={prop.propertyName} required={prop.isRequired} htmlFor={prop.propertyDisplayName}>
                {prop.propertyDisplayName}:
              </Label>
              <Combobox
                  id={prop.propertyName}
                  value={prop.value}
                  onOptionSelect={(event, d) => handleDropDownValueChange(d.optionValue, tcLovType)}
                  onOpenChange={(event, d) => handleChangeBox(prop.propertyName, d, event)}
                  onChange={(event: any)=>onInputUpdate(prop, event )}
                >
                {
                  <LovRenderer tcLovType={tcLovType} tcInputFields={tcInputFields}/>
                }
              </Combobox>
            </>
          )
        } else if (prop.maxLength > 128) {
          return (
            <>
              <Label id={prop.propertyName} htmlFor={prop.propertyDisplayName} className='edit-label-width'>
                {prop.propertyDisplayName}:
              </Label>
              <Textarea
                size="large"
                resize="vertical"
                id={prop.propertyName}
                value={prop.value}
                onChange={handleStringPropertyChange}
              />
            </>
          );
        } else {
          return (
            <>
              <Label id={prop.propertyName} htmlFor={prop.propertyDisplayName} className='edit-label-width'>
                {prop.propertyDisplayName}:
              </Label>
              <Input
                type="text"
                id={prop.propertyName}
                value={prop.value}
                placeholder={prop.preferredPattern ? prop.preferredPattern : ""}
                onChange={handleStringPropertyChange}
              />
            </>
          );
        }
      } else if (prop.type === TCDataTypes.Boolean) {
        if (showAsLabel) {
          return (
            <Stack horizontal>
              <Label id={prop.propertyName} htmlFor={prop.propertyDisplayName} className="label-width">
                {prop.propertyDisplayName}:
              </Label>
              <Label id={prop.propertyName}>{prop.value.toLowerCase() === 'true' ? 'True' : 'False'}</Label>
            </Stack>
          );
        } else {
          return (
            <>
              <Checkbox label={prop.propertyDisplayName} checked={prop.value.toLowerCase() === 'true' ? true: false} id={prop.propertyName} onChange={handleBooleanPropertyChange}/>
            </>
          );
        }
      } else if (prop.type === TCDataTypes.Date) {
        if(showAsLabel) {
          return(
            <Stack horizontal>
              <Label id={prop.propertyName} htmlFor={prop.propertyDisplayName} className='label-width'>
                {prop.propertyDisplayName}:
              </Label>
              <Label id={prop.propertyName}>
                {currentTimeZoneDate(prop.dbValues)}
              </Label>
            </Stack>);
        }
        else{
          return(
            <>
              <Label id={prop.propertyName} htmlFor={prop.propertyDisplayName} className='edit-label-width'>
                {prop.propertyDisplayName}:
              </Label>
              <DateTime currentDate={getCurrentTimeZoneDateForDatePicker(prop.dbValues)} onDateChange={handleDatePropertyChange} errorExists={handleTimePickerError} id={prop.propertyName} isTimeEnabled={prop.isTimeEnabled}/>
            </>
          );
        }
      } else if (prop.type === TCDataTypes.Integer) {
        if (showAsLabel) {
          return(
          <Stack horizontal>
            <Label id={prop.propertyName} htmlFor={prop.propertyDisplayName} className='label-width'>
              {prop.propertyDisplayName}:
            </Label>
            <Label id={prop.propertyName}>
              {prop.value}
            </Label>
          </Stack>);
        } else {
          return (
            <>
              <Label id={prop.propertyName} htmlFor={prop.propertyDisplayName} className='edit-label-width'>
                {prop.propertyDisplayName}:
              </Label>
              <Input
                id={prop.propertyName}
                type="number"
                pattern="[0-9]*"
                onChange={handleStringPropertyChange}
              />
            </>
          );
        }
      } else if (prop.type === TCDataTypes.UntypedReference || prop.type === TCDataTypes.Object) {
        if (showAsLabel) {
          return (
            <Stack horizontal>
              <Label id={prop.propertyName} htmlFor={prop.propertyDisplayName} className='label-width'>
                {prop.propertyDisplayName}:
              </Label>
              <Label id={prop.propertyName}>
                {prop.value}
              </Label>
            </Stack>
          );
        } else if (prop.hasLOV) {
          const tcLovType: TCLOVDataType = tcLovFields[prop.propertyName] as TCLOVDataType;
          return (
            <>
             <Label id={prop.propertyName} required={prop.isRequired} htmlFor={prop.propertyDisplayName}>
                {prop.propertyDisplayName}:
              </Label>
              <Combobox
                  id={prop.propertyName}
                  value={prop.value}
                  onOptionSelect={(event:any, d:any) => handleDropDownValueChange(d.optionValue, tcLovType)}
                  onOpenChange={(event:any, d:any) => handleChangeBox(prop.propertyName, d, event)}
                  onChange={(event: any) => onInputUpdate(prop, event)}
                >
                {<LovRenderer tcLovType={tcLovType} tcInputFields={tcInputFields}/> }
              </Combobox>
            </>
          )
        } else {
          return <></>;
        }
      } else {
        return <></>;
      }
    });
    logger.logTrace(`Exit ${renderWidget.name}`);
    return propList;
  };

  const renderSpinner = (isHidden: boolean, label: string) => {
    var showLabel : string = label + `${problemReport.value}`;
    return (
      <div hidden={isHidden}>
        <Spinner appearance="primary" label={showLabel} />
      </div>
    );
  };

  const getLoadingMessage = () => {
    if (getStyleSheet.isLoading) {
      const loadingLable : string = `Loading the ${problemReport.value} Info panel`;
      return <Spinner appearance="primary" label={loadingLable} />;
    }
    return null;
  };

  const resetPanel = () => {
    window.location.reload();
  }
  // ProblemReportInfo renderer method.
  return (
    <>
      <div hidden={tcInputFields.length > 0}>
        {getLoadingMessage()}
      </div>
      <div hidden={tcInputFields.length === 0}>
        { !isUpdatingPR ? (
            <form className="problem-info-formBody">
              <DialogBody>
              <DialogContent className="problem-info-formContainer">
                {renderWidget()}
                {
                  attachedItem.name?(
                    <Stack horizontal >
                      <Label className='label-width'>{ListObjectUtils.getLabelForItemPicker(problemReport.type)}:</Label>
                      <Label>{attachedItem.name}</Label>
                    </Stack>
                  ):(null)
                }
                {
                  problemImageTicket?(
                  <>
                  <Label className='label-width'>
                    Reference Items:
                  </Label>
                  {<div className="problem-info-image-container">
                  <ProblemReportImage id={tcInputFields[0].value} imageTicket={problemImageTicket}/>
                  </div>}
                  </>
                  ):(null)
                }
                {(modelObjects && problemReport.type===Constants.simpleChangeRevision) ? (<ParticipantsInfo modelObjects={modelObjects} object={problemReport} inEditMode={inEditMode}/>):(<></>)}
              </DialogContent>
              <DialogActions>
                {
                  inEditMode? (
                    <div className="problem-info-dialog-action"><Button appearance="secondary" onClick={resetPanel}>
                      Cancel
                    </Button><Button appearance="primary" disabled={isUpdateButtonDisabled()} onClick={handleUpdate}>
                      Save
                    </Button></div>
                  ):(
                    <div className="problem-info-dialog-action">
                    <Button appearance="secondary" onClick={CloseProblemReport}>
                      Close
                    </Button><Button appearance="primary" onClick={handleEdit}>
                      Edit
                    </Button></div>
                  )
                }
              </DialogActions>
              </DialogBody>
            </form>
          ): (
          <>{renderSpinner(false, "Updating the ")}</>
          )
        }
      </div>
    </>
  );
};
export default ProblemReportInfo;
