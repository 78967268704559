import { logger } from "../../Logger";
import { Configuration } from "../../services/Configuration";
import { Constants } from "../common/Constants";

/**@class ProblemDetailsUtils
 * @description: Class having various utility methods required for handling creation of Problem Report.
 */
export class ProblemDetailsUtils {

  /**
   * @function checkIsHandleTellMeFeatureEnabled
   * @description method to identify if the tell me feature should be enabled or not.
   * @param configuration {any} input configuration.
   * @param selectedType {string} selected problem report type. Default is empty string.
   * @returns {boolean} true if TellMe feature is enabled else false. 
   */
  public static checkIsHandleTellMeFeatureEnabled(configuration: any, selectedType: string=""): boolean {
    logger.logTrace(`Entered ${this.checkIsHandleTellMeFeatureEnabled.name}`);
    let retVal = false;
    const openAIOptnInValue: boolean = !!Configuration.getConfigurationForAttribute(Constants.openAIOptIn, configuration);
    if (openAIOptnInValue) {
      const prSchema: string = Configuration.getConfigurationForAttribute(Constants.problemReportFieldsDescription, configuration);
      const prSchemaJson: any = JSON.parse(prSchema);
      if (prSchemaJson && prSchemaJson.length > 1) {
        for (const prSchemaType of prSchemaJson) {
          const prTypeName: string[] = Object.keys(prSchemaType);
          if (prTypeName && prTypeName.length === 1 && prTypeName[0] === selectedType) {
            retVal = true;
            break;
          }
        }
      }
    }
    logger.logTrace(`Exit ${this.checkIsHandleTellMeFeatureEnabled.name}`);
    return retVal;
  }

  /**
   * @function getTitleForHandleTellMeFeature
   * @description method to get the tooltip information for the tellme button
   * @param configuration {any} input configuration.
   * @param selectedType {string} selected problem report type. Default is empty string.
   * @returns {string} tooltip string for the tell me button.
   */
  public static getTitleForHandleTellMeFeature(configuration: any, selectedType: string=""): string {
    logger.logTrace(`Entered ${this.getTitleForHandleTellMeFeature.name}`);
    let retVal = "";
    const openAIOptnInValue: boolean = !!Configuration.getConfigurationForAttribute(Constants.openAIOptIn, configuration);
    if (openAIOptnInValue) {
      const prSchema: string = Configuration.getConfigurationForAttribute(Constants.problemReportFieldsDescription, configuration);
      const prSchemaJson: any = JSON.parse(prSchema);
      let foundFlag = false;
      if (prSchemaJson && prSchemaJson.length > 1) {
        for (const prSchemaType of prSchemaJson) {
          const prTypeName: string[] = Object.keys(prSchemaType);
          if (prTypeName && prTypeName.length === 1 && prTypeName[0] === selectedType) {
            retVal = "Summarize the object creation using the OpenAI feature.";
            foundFlag =  true;
            break;
          }
        }
      }
      if (foundFlag === false) {
        retVal = "Selected object's schema definition missing in the configuration.";
      }
    } else {
      retVal = "Open AI opt-in value is not enabled in the configuration.";
    }
    logger.logTrace(`Exit ${this.getTitleForHandleTellMeFeature.name}`);
    return retVal;
  }
}