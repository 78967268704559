import {
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState
} from "react";
import { app, dialog, HostClientType } from "@microsoft/teams-js";
import {
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Theme,
} from "@fluentui/react-components";
import config from "../config/config";
import { TeamsUserCredential, UserInfo } from "@microsoft/teamsfx";
import { useLocalStorage } from "./useStorage";
import { QueryClient } from "react-query";
import { logger } from '../Logger';
import { Constants } from "../components/common/Constants";
import { RequestUtils } from "../services/RequestUtils";
import { ApiResponse } from "../types/ApiRequest";
import { AuthenticationUtils } from "../components/authentication/AuthenticationUtils";
import { ISAMConfig, AuthCallResponse, IAuthCallResponse } from "../types/CommonTypes";

/**
 * Returns the full context of our teams application, with current theme, user information, and teamsUserCredential utilities
 *
 */
export const useTeamsContext = (
  queryClient: QueryClient
): {
  theme: Theme;
  themeString: string;
  context: app.Context | undefined;
  teamsUserCredential: TeamsUserCredential;
  userInfo: UserInfo | undefined;
  clientType: HostClientType | undefined;
  configuration: any;
  userConfiguration: any;
  teamcenter: {
    session: IAuthCallResponse;
    setSession: (value: IAuthCallResponse) => void;
    isAuthenticated: boolean;
    setTCisAuthenticated: (value: boolean) => void;
    isAuthenticating: boolean;
    logout: () => void;
    login: () => Promise<void>;
  };
  failure: {
    error: { title: string; message: string; statusCode: number, correlationId: string } | undefined;
    setError: Dispatch<
      SetStateAction<
        { title: string; message: string; statusCode: number, correlationId: string } | undefined
      >
    >;
  };
  queryClient: QueryClient;
} => {

  logger.logCorrelationCreate();
  logger.setActionId("useTeamsContext");
  logger.setRequestId();
  logger.logInformation("useTeamsContext() called.");

  const [theme, setTheme] = useState<Theme>(teamsLightTheme);
  const [themeString, setThemeString] = useState<string>("default");
  const [context, setContext] = useState<app.Context | undefined>(undefined);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const [clientType, setClientType] = useState<HostClientType | undefined>(undefined);
  const [teamcenterCode, setTeamcenterCode] = useLocalStorage<AuthCallResponse | undefined>(Constants.cacheTeamcenterCode, undefined);
  const [error, setError] = useState<
    { title: string; message: string; statusCode: number, correlationId: string } | undefined
  >(undefined);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    teamcenterCode !== undefined && !!teamcenterCode.sessionId
  );

  const [configuration, setConfiguration] = useLocalStorage<any>(Constants.cacheConfiguration, undefined);
  const [userConfiguration, setUserConfiguration] = useLocalStorage<any>(Constants.cacheUserConfiguration, undefined);

  // Read auth configuration
  // Create an instance of teamsUserCredential
  const teamsUserCredential = useMemo(() => {
    const authConfig = {
      initiateLoginEndpoint: config.initiateLoginEndpoint,
      clientId: config.clientId,
    };

    return new TeamsUserCredential(authConfig);
  }, []);

  // handler to change theme
  const themeChangeHandler = useCallback((theme: string | undefined) => {
    setThemeString(theme || "default");
    switch (theme) {
      case "dark":
        setTheme(teamsDarkTheme);
        break;
      case "contrast":
        setTheme(teamsHighContrastTheme);
        break;
      case "default":
      default:
        setTheme(teamsLightTheme);
    }
  }, []);

  // subscribing to app theme change handler
  const subscribeAsync = useCallback(async () => {
    await app.initialize();
    const context = await app.getContext();
    themeChangeHandler(context.app.theme);
    setClientType(context.app.host.clientType);
    const userInfo = await teamsUserCredential.getUserInfo();
    setUserInfo(userInfo);
    setContext(context);
    app.registerOnThemeChangeHandler(themeChangeHandler);
  }, [teamsUserCredential, themeChangeHandler]);

  // subscribing during layout effect
  useLayoutEffect(() => {
    (async () => {
      await subscribeAsync();
    })();
  }, [subscribeAsync]);

  const openDataPrivacyDialog = useCallback(() => {
    const taskInfo = {
      title: "Data Privacy",
      url: window.location.origin + "/index.html#/dataprivacyinitial",
      card: null,
      size: { height: 600, width: window.innerWidth > 1000 ? 450: 350 },
    };
    dialog.open(taskInfo, () => queryClient.invalidateQueries());
  }, [queryClient]);

  const extractAnalyticsInfo = (analyticsResponse: ApiResponse) => {
    let retVal: any = undefined;
    if (analyticsResponse.error) {
      failure.setError(analyticsResponse.error);
    } else if (analyticsResponse.data) {
      retVal = {
        userId: analyticsResponse.data.userId,
        customerId: analyticsResponse.data.customerId,
        tcServerVersion: analyticsResponse.data.tcServerVersion,
        tcPlatformLocale: analyticsResponse.data.tcPlatformLocale
      }
    } else {
      failure.setError({
        correlationId:"",
        message: "The application could not get analytics info from server.",
        statusCode: 450,
        title: "Failed to get analytics info."
      });
    }
    return retVal;
  }  

  const teamcenter = {
    // using a get functions instead of a classic properties to be sure useApi will re evaluate every time
    get session() {
      return teamcenterCode;
    },

    get isAuthenticated() {
      return isAuthenticated;
    },

    setTCisAuthenticated: (value: boolean) => {
      setIsAuthenticated(value);
    },

    setSession: (value: IAuthCallResponse) => {
      setTeamcenterCode(value);
    },

    get isAuthenticating() {
      return isAuthenticating;
    },

    logout: () => {
      queryClient.clear();
      setIsAuthenticated(false);
      setIsAuthenticating(false);
      //if user is logging out then clear local cache
      window.localStorage.removeItem(Constants.cacheTeamcenterCode);
      window.localStorage.removeItem(Constants.cacheConfiguration);
      window.localStorage.removeItem(Constants.cacheUserConfiguration);
      setConfiguration(undefined);
      setUserConfiguration(undefined);
      setTeamcenterCode(undefined);
    },
    login: async () => {
      if (!context?.app.sessionId) {
        return;
      }
      const configurationResponse: any = await RequestUtils.callTcTeamsApi(Constants.opConfiguration, teamsUserCredential, undefined);
      if (configurationResponse.error) {
        failure.setError(configurationResponse.error);
      } else if (configurationResponse && configurationResponse.data) {
        setIsAuthenticating(true);
        setConfiguration(configurationResponse.data);
        // Getting the SAM enabled configuration
        const samConfig: ISAMConfig = AuthenticationUtils.checkForSAMAuthentication(configurationResponse.data);
        // Starting the authentication flow
        failure.setError(undefined);
        const tcCode: AuthCallResponse = await AuthenticationUtils.authenticateAsync(context?.app.sessionId, teamsUserCredential, samConfig);
        if (tcCode.error) {
          setIsAuthenticated(false);
          failure.setError(tcCode.error);
        } else if (
          tcCode &&
          tcCode.sessionId &&
          tcCode.userId &&
          tcCode.userUid
        ) {
          setIsAuthenticated(true);
          if (!tcCode.ecaId) {
            // Calling the session analytics API to get analytics information.
            const analyticsResponse: ApiResponse = await RequestUtils.callTcTeamsApi(Constants.opGetSessionanalyticsInfo, teamsUserCredential, tcCode);
            tcCode.analyticsInfo = extractAnalyticsInfo(analyticsResponse);
            tcCode.ecaId = tcCode.analyticsInfo?.customerId;
          }
          setTeamcenterCode(tcCode);
          // Call the API call to get User Configuration
          const userConfigResponse: ApiResponse = await RequestUtils.callTcTeamsApi(Constants.opUserConfiguration, teamsUserCredential, tcCode);
          if (userConfigResponse.error) {
            failure.setError(userConfigResponse.error);
          } else if (userConfigResponse && userConfigResponse.data) {
            setUserConfiguration(userConfigResponse.data);
            if (configurationResponse.data[Constants.tenantTelemetryOptIn]) {
              const telemetryOptInValue: boolean = configurationResponse.data[Constants.tenantTelemetryOptIn];
              if (telemetryOptInValue === true) {
                const userProductExcellenceTelemetryOptIn: any = userConfigResponse.data[Constants.userProductExcellenceTelemetryOptIn];
                if (userProductExcellenceTelemetryOptIn === null) {
                  openDataPrivacyDialog();
                }
              }
            }
          }
        } else {
          setIsAuthenticated(false);
        }
      }
      setIsAuthenticating(false);
    },
  };

  const failure = { setError, error };

  logger.clearActionId();
  logger.clearRequestId();

  return {
    theme,
    themeString,
    context,
    teamsUserCredential,
    userInfo,
    clientType,
    configuration,
    userConfiguration,
    teamcenter,
    failure,
    queryClient,
  };
};
