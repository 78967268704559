import { logger } from "../../Logger";
import { Constants } from "../common/Constants";
import { RequestUtils } from "../../services/RequestUtils";
import { ApiResponse } from "../../types/ApiRequest";
import { IGetDeclarativeStyleSheetsRequestData, ModelObject, StyleSheetType } from "../common/TcSOATypes";
import { ParticipantTypeObject, ParticipantObject , AddParticipantInputStructure, RemoveParticipantInputStructure} from "../../types/CommonTypes";
import { TCInputTypeUtils } from "../../services/TCInputTypeUtils";

/**@class ParticipantsUtils
 * @description: Class having various utility methods required for handling Participants.
 */
export class ParticipantsUtils {

  /**
   * @function getTypeConstantValuesInput
   * @description method to create input structure for getTypeConstantValues API call.
   * @param participantTypes {string[]} array of participant types.
   * @returns {any} input for the getTypeConstantValues API call. 
   */
  public static getTypeConstantValuesInput(participantTypes: string[]): any {
    logger.logTrace(`Entered ${this.getTypeConstantValuesInput.name}`);
    const constantTypeObjects: any = [];
    for(let key in participantTypes){
      const objectType = {
        "typeName": participantTypes[key],
        "constantName": "ParticipantAllowMultipleAssignee"
      }
      constantTypeObjects.push(objectType);
    }
    logger.logTrace(`Exit ${this.getTypeConstantValuesInput.name}`);
    return {
      "constantTypeObjects": constantTypeObjects
    };
  }

  /**
   * @function populateAssignableParticipantTypeNameMap
   * @description method to create map of internal and display values of participant types.
   * @param modelObjects {any} set of model objects received from get stylesheet api call.
   * @param object {ModelObject} Currently opened object details.
   * @returns {any} map of internal and display values of participant types. 
   */
  public static populateAssignableParticipantTypeNameMap(modelObjects:any, object:ModelObject):any {
    logger.logTrace(`Entered ${this.populateAssignableParticipantTypeNameMap.name}`);
    const targetObject = modelObjects[object.uid];
    const participantTypeMap: any = {};
    const participantTypes = targetObject.props.assignable_participant_types.dbValues;
    if( participantTypes && participantTypes.length > 0 ) {
      for( var idx = 0; idx < participantTypes.length; idx++ ) {
        let typeName =  participantTypes[idx].split( '::' )[ 1 ];
        let uiValue = targetObject.props.assignable_participant_types.uiValues[ idx ];
        // If uiValue is still null then use the internal name as display name
        if( !uiValue ) {
            uiValue = typeName;
        }
        participantTypeMap[ typeName ] = uiValue;
      }
    }
    logger.logTrace(`Exit ${this.populateAssignableParticipantTypeNameMap.name}`);
    return participantTypeMap;
  };

  /**
   * @function processConstantValuesResponse
   * @description method to create array of all participant types with their details.
   * @param data {any} constant values set as per type constants.
   * @param modelObjects {any} set of model objects received from get stylesheet api call.
   * @param object {ModelObject} Currently opened object details.
   * @returns {ParticipantTypeObject[]} array of all participant types with their details. 
   */
  public static processConstantValuesResponse(data:any, modelObjects:any, object:ModelObject): ParticipantTypeObject[] {
    logger.logTrace(`Entered ${this.processConstantValuesResponse.name}`);
    const allParticipants:string[] = modelObjects[object.uid].props.participants.dbValues;
    const assignableParticipantTypeNameMap:any = ParticipantsUtils.populateAssignableParticipantTypeNameMap(modelObjects,object);
    let participantsArray: ParticipantTypeObject[] = [];
    for(const participant in allParticipants){
      const participantObject = modelObjects[allParticipants[participant]];
      const participantType = data.find((type:any) => type.key.typeName === participantObject.type);
      const participantExists = participantsArray.find((objectType:ParticipantTypeObject) => objectType.typeName === participantType.key.typeName);
      if(participantExists){
        const newAssignee : ParticipantObject = {
          "uid": participantObject.uid,
          "type": participantObject.type,
          "displayValue": participantObject.props.object_string.uiValues[0]
        };
        participantExists.assigneeList.push(newAssignee);
      }else{
        const newAssignee : ParticipantObject = {
          "uid": participantObject.uid,
          "type": participantObject.type,
          "displayValue": participantObject.props.object_string.uiValues[0]
        };
        const newParticipantObject: ParticipantTypeObject = {
          "typeName": participantType.key.typeName,
          "displayName": assignableParticipantTypeNameMap[participantType.key.typeName],
          "multipleAssignee": participantType.value==="true" ? true : false,
          "assigneeList": [newAssignee]
        };
        participantsArray.push(newParticipantObject);
      }
    }
    for(const entry in data){
      if(!participantsArray.find((objectType:ParticipantTypeObject) => objectType.typeName === data[entry].key.typeName)){
        const newParticipantObject: ParticipantTypeObject = {
          "typeName": data[entry].key.typeName,
          "displayName": assignableParticipantTypeNameMap[data[entry].key.typeName],
          "multipleAssignee": data[entry].value==="true" ? true : false,
          "assigneeList": []
        };
        participantsArray.push(newParticipantObject);
      }
    }
    logger.logTrace(`Exit ${this.processConstantValuesResponse.name}`);
    return participantsArray;
  };

  /**
   * @function getAllParticipants
   * @description method to get array of all participant types with their details.
   * @param modelObjects {any} set of model objects received from get stylesheet api call.
   * @param object {ModelObject} Currently opened object details.
   * @param teamsContext {any} Teams context object.
   * @returns {ParticipantTypeObject[]} array of all participant types with their details. 
   */
  public static async getAllParticipants(modelObjects:any, object:ModelObject, teamsContext:any): Promise<ParticipantTypeObject[]> {
    logger.logTrace(`Entered ${this.getAllParticipants.name}`);
    let participants: ParticipantTypeObject[] = [];
    const getPreferenceInput = {
      "preferenceNames": [Constants.simpleChangeRevision + "_displayable_participant_types"]
    };
    const getPreferenceResponse: ApiResponse = await RequestUtils.callTcTeamsApi(Constants.getPreference,teamsContext.teamsUserCredential, teamsContext.teamcenter.session, getPreferenceInput);
    const preferenceValues: string[] = getPreferenceResponse.data.response[0].values.values;
    if(preferenceValues.length > 0){
      const getTypeConstantValuesResponse: ApiResponse = await RequestUtils.callTcTeamsApi(Constants.getTypeConstantValues,teamsContext.teamsUserCredential, teamsContext.teamcenter.session, ParticipantsUtils.getTypeConstantValuesInput(preferenceValues));
      if(getTypeConstantValuesResponse.data?.constantValues?.length > 0){
        participants = ParticipantsUtils.processConstantValuesResponse(getTypeConstantValuesResponse.data.constantValues,modelObjects,object);
      }
    }
    logger.logTrace(`Exit ${this.getAllParticipants.name}`);
    return participants;
  };

  /**
   * @function getAddParticipantInput
   * @description method to get array of all selected participant for AddParticipant call.
   * @param selectedParticipant {ParticipantObject[]} set of selected participants.
   * @param participantObject {ParticipantTypeObject} Currently opened Participant type.
   * @param openedObject {ModelObject} Currently opened object details.
   * @returns {AddParticipantInputStructure} array of all participant types with their details. 
   */
  public static getAddParticipantInput(selectedParticipant: ParticipantObject[], participantObject: ParticipantTypeObject, openedObject:ModelObject): AddParticipantInputStructure {
    logger.logTrace(`Entered ${this.getAddParticipantInput.name}`);
    const selectedParticipantsArray: any = [];
    for(const participant in selectedParticipant){
      const userObject = selectedParticipant[participant];
      selectedParticipantsArray.push({
        "assignee":{
          "type":userObject.type,
          "uid":userObject.uid
        },
        "participantType":participantObject.typeName
      });
    }
    const addParticipantInput:AddParticipantInputStructure = {
      "wso": {
        "type":openedObject.type,
        "uid":openedObject.uid
      },
      "participantInputData": selectedParticipantsArray
    };
    logger.logTrace(`Exit ${this.getAddParticipantInput.name}`);
    return addParticipantInput;
  };

  /**
   * @function getRemoveParticipantsInput
   * @description method to get array of all participant types with their details for removeParticipant call.
   * @param selectedRemoveParticipants {ParticipantObject[]} set of selected participants.
   * @param openedObject {ModelObject} Currently opened object details.
   * @returns {ParticipantTypeObject[]} array of all participant types with their details. 
   */
  public static getRemoveParticipantsInput(selectedRemoveParticipants: ParticipantObject[], openedObject:ModelObject): RemoveParticipantInputStructure {
    logger.logTrace(`Entered ${this.getRemoveParticipantsInput.name}`);
    const selectedRemoveParticipantArray: any = [];
    for(const participant in selectedRemoveParticipants){
      const userObject = selectedRemoveParticipants[participant];
      selectedRemoveParticipantArray.push({
        "type":userObject.type,
        "uid":userObject.uid
      });
    }
    const removeParticipantInput = {
      "itemRev": {
        "type":openedObject.type,
        "uid":openedObject.uid
      },
      "participant": selectedRemoveParticipantArray
    };
    const removeParticipantInputStructure: RemoveParticipantInputStructure = {
      "participants":[removeParticipantInput]
    };
    logger.logTrace(`Exit ${this.getRemoveParticipantsInput.name}`);
    return removeParticipantInputStructure;
  };

  /**
   * @function isRemoveParticipantSelected
   * @description Methods to check if the participant is selected or not.
   * @param assignee {ParticipantObject} current selection
   * @param selectedRemoveParticipants {ParticipantObject[]} set of selected remove participants.
   * @returns {boolean} true if selected, false otherwise. 
   */
  public static isRemoveParticipantSelected(assignee: ParticipantObject, selectedRemoveParticipants:ParticipantObject[]): boolean {
    const user= selectedRemoveParticipants.find((user:ParticipantObject) => user.uid === assignee.uid);
    if(user){
      return true;
    }
    return false;
  };

  /**
   * @function refetchObject
   * @description method to get all modelObjects after add/remove participant call.
   * @param openedObject {ParticipantObject} Currently opened object details.
   * @returns {any} array of all recently fetched model objects. 
   */
  public static async refetchObject(openedObject:ModelObject,teamsContext:any): Promise<any> {
    logger.logTrace(`Entered ${this.refetchObject.name}`);
    let modelObjects:any;
    const requestData: IGetDeclarativeStyleSheetsRequestData =
      TCInputTypeUtils.getXRTRequestData(
        openedObject.type,
        StyleSheetType.INFO,
        openedObject
      );
    if(requestData){
      const getStyleSheetResponse: ApiResponse = await RequestUtils.callTcTeamsApi(Constants.getDeclarativeStyleSheets,teamsContext.teamsUserCredential, teamsContext.teamcenter.session, requestData);
      if(getStyleSheetResponse.data?.modelObject){
        modelObjects = getStyleSheetResponse.data.modelObject;
      }
    };
    logger.logTrace(`Exit ${this.refetchObject.name}`);
    return modelObjects;
  };
  
  /**
   * @function getModifiedParticioants
   * @description method to update participant list according to modification.
   * @param modelObjects {any} set of model objects received from get stylesheet api call.
   * @param participants {ParticipantTypeObject[]} Currently opened object details.
   * @param object {ModelObject} Currently opened object details.
   * @returns {ParticipantTypeObject[]} array of all participant types with their details. 
   */
  public static getModifiedParticipants(modelObjects:any, participants:ParticipantTypeObject[], object:ModelObject): ParticipantTypeObject[] {
    logger.logTrace(`Entered ${this.getModifiedParticipants.name}`);
    //empty assignee list for participant type
    let newParticipantObjectArray: ParticipantTypeObject[] = [...participants];
    for(const participant in newParticipantObjectArray){
      newParticipantObjectArray[participant].assigneeList = [];
    }
    //populate assignee list for newParticipantObjectArray
    const allParticipants:string[] = modelObjects[object.uid].props.participants.dbValues;
    for(const participant in allParticipants){
      const participantObject = modelObjects[allParticipants[participant]];
      const participantExists = newParticipantObjectArray.find((objectType:ParticipantTypeObject) => objectType.typeName === participantObject.type);
      if(participantExists){
        const newAssignee : ParticipantObject = {
          "uid": participantObject.uid,
          "type": participantObject.type,
          "displayValue": participantObject.props.object_string.uiValues[0]
        };
        participantExists.assigneeList.push(newAssignee);
      }
    }
    logger.logTrace(`Exit ${this.getModifiedParticipants.name}`);
    return newParticipantObjectArray;
  };
}
