import config from '../config/config';
import { useApi } from './useApi';
import { ApiRequestReturnType, UseTypeQueryResult } from "../types";
import { logger } from '../Logger';
import { MethodType } from '../types/ApiRequest';

export const useGetOnboardedStatus = (): UseTypeQueryResult<{ isOnboarded: any }> => {
  const response = useApi({
    url: `${config.tcTeamsWebApiUrl}/onboardedstatus`,
    method: MethodType.GET,
    enabled: true,
    action: 'onboardedstatus',
    returnType: ApiRequestReturnType.text
  }, logger);

  return {
    get isOnboarded() {
      return response?.data;
    },
    ...response,
  };
};
