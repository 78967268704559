/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { TeamsFxContext } from '../components/Context';
import { getResponseUsingResponseType, handleTcTeamsAPIError } from '../services/utility';
import { ApiRequest } from '../types';
import { TeamsTeamcenterLogger }  from 'teamsteamcenterobservability'
import { ErrorMessage } from '../types/CommonTypes';

/**
 * allows to call any APIS from the backend, with the authentication bearer token.
 *
 * @param request Api request object
 * @return {UseQueryResult} query result
 */
export const useApi = (request: ApiRequest, logger: TeamsTeamcenterLogger): UseQueryResult<any, any> => {
  const teamsContext = useContext(TeamsFxContext);

  let callKey = request.key ? request.key : request.url;
  callKey = callKey instanceof Array ? callKey : [callKey];

  // calling API
  const queryResult: UseQueryResult<any, Error> = useQuery<any, Error>(callKey,()=>callApiAsync(request, logger, teamsContext), {
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: request.enabled,
    retry: false,
    useErrorBoundary: false,
    onSuccess: () => {
      if (request.invalidateQuery) {
        teamsContext?.queryClient?.invalidateQueries({
          predicate: (query) => {
            for (const key of query.queryKey as string[]) {
              const invalidate = (request.invalidateQuery && request.invalidateQuery(key)) || false;
              if (invalidate) {
                return true;
              }
            }
            return false;
          },
        });
      }
    },
  });

  return { ...queryResult };
};

export const callApiAsync = async (request: ApiRequest,logger: TeamsTeamcenterLogger, teamsContext: any ) => {
  const accessToken = await teamsContext?.teamsUserCredential?.getToken('');

  if (!accessToken || !request.url || !request.enabled) {
    return null;
  }

  // Get TenantId for logger
  if (typeof teamsContext?.userInfo !== 'undefined' && teamsContext?.userInfo !== null) {
    const tenantId = teamsContext?.userInfo?.tenantId;
    if(tenantId !== null) {
      logger.setTenantId(tenantId);
    }
  }

  if (request.action) {
    logger.logCorrelationCreate();
    logger.setActionId(request.action);
    logger.setRequestId();
    logger.logInformation(request.action + "() called.");
  }

  const correlationId = logger.getRequestId();

  // preparing the headers
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${accessToken.token}`);
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  headers.append('sessionId', teamsContext?.teamcenter.session?.sessionId || '');
  headers.append('userId', teamsContext?.teamcenter.session?.userId || '');
  headers.append('x-siemens-session-id', logger.getSessionId());
  headers.append('x-siemens-operation-id', logger.getActionId());
  headers.append('x-correlation-id', correlationId);

  if (request.headers) {
    request.headers.forEach((header) => {
      headers.append(header[0], header[1]);
    });
  }
  const requestInit: RequestInit = {
    method: request.method ?? 'GET',
    headers: headers,
  };

  if (request.method && (request.method === 'POST' || request.method === 'PUT')) {
    requestInit.body = request.body;
  }

  const response = await fetch(request.url, requestInit);

  if (request.action && response) {
    logger.logInformation(request.action + "() completed.");
    logger.clearActionId();
    logger.clearRequestId();
  }

  if (!response || response.status < 200 || response.status > 204) {
    const errorMessage: ErrorMessage = await handleTcTeamsAPIError (request.url, correlationId, response);
    teamsContext?.failure.setError(errorMessage);
  }

  return await getResponseUsingResponseType(request.returnType, response);
};
