import { logger } from "../../Logger";
import { Constants } from "../common/Constants";
import { RequestUtils } from "../../services/RequestUtils";
import { ApiResponse } from "../../types/ApiRequest";


/**@class WorkflowUtils
 * @description: Class having various utility methods required for handling Workflow tasks.
 */

type LabelIndex = {
  index: string;
  label: string;
};

export class WorkflowUtils {
  /**
   * @function getDecisionLabels
   * @description Method to get decisionlabels from the decision set LOV
   * @param modelObjects {any} input declarative JSON response
   * @param taskUid wf task uid.
   * @returns {LabelIndex[]} decision set LOV value.
   */
  public static getDecisionLabels( modelObjects: any, taskUid: string): LabelIndex[] {
    logger.logTrace(`Entered ${this.getDecisionLabels.name}`);
    var buttonLabels: LabelIndex[] = [];
    if(modelObjects && modelObjects[taskUid] && modelObjects[taskUid].props && modelObjects[taskUid].props.fnd0DecisionSetLOV && modelObjects[taskUid].props.fnd0DecisionSetLOV.dbValues){
      const decisionSetLOVUid = modelObjects[taskUid].props.fnd0DecisionSetLOV.dbValues[0];
      if(modelObjects[decisionSetLOVUid] && modelObjects[decisionSetLOVUid].props){
        const lovProps = modelObjects[decisionSetLOVUid].props;
        const lovValues = lovProps.lov_values?.dbValues;
        const lovDescriptions = lovProps.lov_value_descriptions?.uiValues;
        if( lovValues && lovDescriptions ) {
          var approveIdx = lovValues.indexOf( '89' );
          var rejectIdx = lovValues.indexOf( '78' );
          //Label for approve path
          buttonLabels.push({index:'89', label:lovDescriptions[ approveIdx ]});
          //Label for reject path
          if( rejectIdx !== -1 ) {
            buttonLabels.push({index:'78',label:lovDescriptions[ rejectIdx ]});
          }
        }
      }
    }
    logger.logTrace(`Exit ${this.getDecisionLabels.name}`);
    return buttonLabels; 
  };


  /**
   * @function getConditionTaskLabels
   * @description method to get all condition task labels
   * @param modelObjects {any} input declarative JSON response
   * @param taskUid wf task uid.
   * @paramm teamsContext {any} teams context.
   * @returns {string[]} decision set LOV value.
   */
  public static async getConditionTaskLabels( modelObjects: any, taskUid: string, teamsContext:any): Promise<string[]> {
    logger.logTrace(`Entered ${this.getConditionTaskLabels.name}`);
    const labels: string[] = [];
    const tasktemplateUid = modelObjects[taskUid]?.props?.task_template?.dbValues[0];
    if(tasktemplateUid && modelObjects[tasktemplateUid]){
      const requestData: any = [{
        Type: modelObjects[tasktemplateUid].type,
        Uid: tasktemplateUid
      }];
      const conditionTasksResultsResponse: ApiResponse = await RequestUtils.callTcTeamsApi(Constants.getTaskResults,teamsContext.teamsUserCredential, teamsContext.teamcenter.session, requestData);
      const taskResults = conditionTasksResultsResponse?.data?.output[0]?.taskResults;
      if(taskResults){
        for(const value of taskResults){
          labels.push(value);
        }
      }
    }
    logger.logTrace(`Exit ${this.getConditionTaskLabels.name}`);
    return labels;
  };
};
