import config from "../config/config";
import { useApi } from './useApi';
import { UseTypeQueryResult } from '../types';
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { logger } from '../Logger';
import { MethodType } from "../types/ApiRequest";

/**
 * Get a list of Creatable Change Types from endpoint that is protected by Azure AD and Teamcenter session
 *
 */
export const useGetCreatableChangeTypes = (baseTypeName: string): UseTypeQueryResult<{ allowedChangeTypes: any }> => {
  const teamsContext = useContext(TeamsFxContext);

  const response = useApi({
    url: `${config.tcTeamsWebApiUrl}/createtcobject/creatablechangetypes?baseTypeName=${baseTypeName}`,
    method: MethodType.GET,
    key: ['creatableChangeTypes', baseTypeName],
    enabled: teamsContext.teamcenter.isAuthenticated && !teamsContext.teamcenter.isAuthenticating,
    action: 'useGetCreatableChangeTypes'
  }, logger);
  
  return {
    get allowedChangeTypes() {
      return response?.data;
    },
    ...response,
  };
};
