import config from "../config/config";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { useApiMutation } from "./useApiMutation";
import { logger } from "../Logger";
import {PerformActionRequestData } from "../components/common/TcSOATypes";
import { MethodType } from "../types/ApiRequest";

/**
 * Get the declarative view model json for the loadEdit request for given business object.
 */
export const usePerformAction = () => {
  const teamsContext = useContext(TeamsFxContext);
  const mutation = useApiMutation<PerformActionRequestData[], any>({
    url: `${config.tcTeamsWebApiUrl}/performaction`,
    method: MethodType.POST,
    key: "PERFORM",
    enabled: teamsContext.teamcenter.isAuthenticated && !teamsContext.teamcenter.isAuthenticating,
    action: 'usePerformAction'
  }, logger);

  return mutation;
};
