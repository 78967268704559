import { logger } from "../Logger";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import config from "../config/config";
import { useApiMutation } from "./useApiMutation";
import { OpenAIInput } from "../types/CommonTypes";
import { MethodType } from "../types/ApiRequest";

/**
 * Summarize PR request to /oai endpoint that is protected by Azure AD and Teamcenter session
 */
export const useOpenAI = () => {
  const teamsContext = useContext(TeamsFxContext);
  const mutation = useApiMutation< OpenAIInput, any>({
    url: `${config.tcTeamsWebApiUrl}/summarize`,
    method: MethodType.POST,
    key: 'openai',
    enabled: teamsContext.teamcenter.isAuthenticated && !teamsContext.teamcenter.isAuthenticating,
    action: 'useOpenAI'
  }, logger);

  return mutation;
};
