
const enum ITeamsTeamcenterLoggerSeverityLevelEnum {
    None = 0,
    Debug = 1,
    Trace = 2,
    Information = 3,
    Warning = 4,
    Error = 5,
    Critical = 6
}

export const ITeamsTeamcenterLoggerSeverityLevel = {
    None: "None",
    Debug: "Debug",
    Trace: "Trace",
    Information: "Information",
    Warning: "Warning",
    Error: "Error",
    Critical: "Critical"
} as const;

//'enum like' "TS type" creation
export type ITeamsTeamcenterLoggerSeverityLevelType = keyof typeof ITeamsTeamcenterLoggerSeverityLevel;
export function getTeamsTeamcenterLoggerSeverityLevelEnum( level: ITeamsTeamcenterLoggerSeverityLevelType):ITeamsTeamcenterLoggerSeverityLevelEnum
{
    if( level == ITeamsTeamcenterLoggerSeverityLevel.Debug)
        return ITeamsTeamcenterLoggerSeverityLevelEnum.Debug;
    if( level == ITeamsTeamcenterLoggerSeverityLevel.Trace)
        return ITeamsTeamcenterLoggerSeverityLevelEnum.Trace;
    if( level == ITeamsTeamcenterLoggerSeverityLevel.Information)
        return ITeamsTeamcenterLoggerSeverityLevelEnum.Information;
    if( level == ITeamsTeamcenterLoggerSeverityLevel.Warning)
        return ITeamsTeamcenterLoggerSeverityLevelEnum.Warning;
    if( level == ITeamsTeamcenterLoggerSeverityLevel.Error)
        return ITeamsTeamcenterLoggerSeverityLevelEnum.Error;
    if( level == ITeamsTeamcenterLoggerSeverityLevel.Critical)
        return ITeamsTeamcenterLoggerSeverityLevelEnum.Critical;
    return ITeamsTeamcenterLoggerSeverityLevelEnum.Information;
}