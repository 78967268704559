import * as React from 'react';
import { useEffect, useState } from 'react';
import { useGetImage } from '../../hooks/useGetImage';
import { Image } from '@fluentui/react-components';

interface IWorkflowTaskProps {
  imageTicket?: string;
  id?: string;
}

const WorkflowTaskImage: React.FunctionComponent<IWorkflowTaskProps> = (props) => {
  const vt = useGetImage(props.id, props.imageTicket);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [isCached, setIsCached] = useState<boolean>(false);

  // remove cached flag to force a re-creation of the TeamcenterImage url from blob
  useEffect(() => {
    setIsCached(false);
    setImageUrl(undefined);
  }, [props.imageTicket]);

  useEffect(() => {
    try {
      if (!isCached && vt.isFetched) {
        setImageUrl(URL.createObjectURL(vt.image));
        setIsCached(true);
      }
    } catch (error) {
      setImageUrl(undefined);
    }

    return () => {
      if (isCached && imageUrl !== undefined) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [isCached, vt.isFetched, vt.image, imageUrl]);

  return <div className="workflow-task-image"><Image src={imageUrl} style={{ maxWidth: '100%', maxHeight: '100%' }} /></div>;
};

export default WorkflowTaskImage;
