import React from 'react';
import { Menu, MenuTrigger, MenuPopover, MenuList, MenuGroupHeader, MenuItem, ToolbarButton } from '@fluentui/react-components';
import { ChannelArrowLeft28Regular, Person28Regular } from '@fluentui/react-icons';
import { TeamsFxContext } from '../../Context';
import { useLogout } from '../../../hooks/useLogout';
import { dialog } from "@microsoft/teams-js";
import { Constants } from '../Constants';
import { ReactComponent as DataPrivacyIcon } from "../../../Images/uiSettingsDataPrivacy24.svg";
import { AnalyticsManager } from '../../../services/analyticsUtility';
import AnalyticsConstants from "../AnalyticsConstants.json";

const LoginToolbar: React.FunctionComponent = () => {
  const teamsContext = React.useContext(TeamsFxContext);
  const logoutMutation = useLogout();

  const handleLogout = () => {
    const sessionId = teamsContext.teamcenter?.session?.sessionId || '';
    const userUid = teamsContext.teamcenter?.session?.userUid || '';
    logoutMutation.mutate({ sessionId, userUid });
    teamsContext.teamcenter.logout();
  };

  const openDataPrivacyDialog =  React.useCallback(() => {
    const analyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(AnalyticsConstants.cmdDataPrivacy);
    const taskInfo = {
      title: "Data Privacy",
      url: window.location.origin + "/index.html#/dataprivacy",
      card: null,
      size: { height: 600, width: window.innerWidth > 1000 ? 650: 350 },
    };
    dialog.open(taskInfo, () => teamsContext.queryClient.invalidateQueries());
  }, [teamsContext.queryClient]);

  const showDataPrivacy = () => {
    let retVal: boolean = false;
    const localConfiguration: any = window.localStorage.getItem("configuration");
    if (localConfiguration) {
      const configurationObject: any = JSON.parse(localConfiguration);
      if (configurationObject) {
        if (configurationObject[Constants.tenantTelemetryOptIn]) {
          const telemetryOptInValue: boolean = configurationObject[Constants.tenantTelemetryOptIn];
          if (telemetryOptInValue === true) {
            retVal = true;
          }
        }
      }
    }
    return retVal;
  }

  const getThemeClass = () => {
    let retVal: string = "data-privacy-icon-default";
    if (teamsContext.themeString) {
      if (teamsContext.themeString === 'dark') {
        retVal = "data-privacy-icon-dark";
      } else if (teamsContext.themeString === 'contrast') {
        retVal = "data-privacy-icon-contrast";
      }
    }
    return retVal;
  }

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <ToolbarButton aria-label="More" icon={<Person28Regular />} />
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          <MenuGroupHeader>
            {teamsContext.teamcenter.session?.userId}
          </MenuGroupHeader>
          {showDataPrivacy() ? (
            <div>
              <MenuItem className={getThemeClass()} icon={<DataPrivacyIcon/>} onClick={openDataPrivacyDialog} >
                Data Privacy
              </MenuItem>
            </div>
          ) : null}
          <MenuItem icon={<ChannelArrowLeft28Regular />} onClick={handleLogout}>
            Logout Teamcenter
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default LoginToolbar;
