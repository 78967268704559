import { Fragment, useState, KeyboardEvent } from 'react';
import { Input, ToolbarButton } from '@fluentui/react-components';
import { Search24Regular } from '@fluentui/react-icons';

interface SearchToolbarProps {
  onSearchButtonClicked: (searchQuery: string) => void;
  disabled: boolean;
}

const SearchToolbar: React.FunctionComponent<SearchToolbarProps> = ({ onSearchButtonClicked, disabled }: SearchToolbarProps) => {
  const [searchQuery, setSearchQuery] = useState<string>();

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearchButtonClicked ? onSearchButtonClicked(searchQuery ? searchQuery : '') : console.log('No search handler');
    }
  };

  return (
    <Fragment>
      <Input
        appearance="underline"
        onChange={(_event, data) => setSearchQuery(data.value)}
        onKeyDown={handleKeyPress}
        placeholder="Search..."
        className="search-bar"
        disabled={disabled}
      />
      <ToolbarButton
        icon={<Search24Regular fontSize={24} />}
        id="Search"
        disabled={disabled}
        onClick={() => onSearchButtonClicked(searchQuery ? searchQuery : '')}
      ></ToolbarButton>
    </Fragment>
  );
};

export default SearchToolbar;
