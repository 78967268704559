/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { TeamsFxContext } from '../components/Context';
import { getResponseUsingResponseType, handleTcTeamsAPIError } from '../services/utility';
import { ApiRequest, UseMutationTypeQuery } from '../types';
import { TeamsTeamcenterLogger }  from 'teamsteamcenterobservability'
import { ErrorMessage } from '../types/CommonTypes';

/**
 * allows to call any APIS from the backend, with the authentication bearer token.
 *
 * @param request Api request object
 * @return {UseQueryResult} query result
 */
export function useApiMutation<TPayload = void, TResponse = void>(request: ApiRequest, logger: TeamsTeamcenterLogger): UseMutationTypeQuery<TPayload, TResponse> {
  const teamsContext = useContext(TeamsFxContext);
  let callKey = request.key ? request.key : request.url;
  callKey = callKey instanceof Array ? callKey : [callKey];

  // calling API
  return useMutation<TResponse, Error, TPayload, unknown>(callKey, (body: TPayload) => callApiAsync(body, request, logger, teamsContext), {
    onSuccess: () => {
      if (request.invalidateQuery) {
        teamsContext?.queryClient?.invalidateQueries({
          predicate: (query) => {
            for (const key of query.queryKey as string[]) {
              const invalidate = (request.invalidateQuery && request.invalidateQuery(key)) || false;
              if (invalidate) {
                return true;
              }
            }
            return false;
          },
        });
      }
    },
  });
}

export const callApiAsync = async (obj: unknown, request: ApiRequest, logger: TeamsTeamcenterLogger, teamsContext: any) => {
  const accessToken: any = await teamsContext?.teamsUserCredential?.getToken('');

  if (!accessToken || !request.url) {
    return null;
  }

  const isFormdata = obj instanceof FormData;

  // Get TenantId for logger
  if (typeof teamsContext?.userInfo !== 'undefined' && teamsContext?.userInfo !== null) {
    const tenantId = teamsContext?.userInfo?.tenantId;
    if (tenantId !== null) {
      logger.setTenantId(tenantId);
    }
  }

  if (request.action) {
    logger.logCorrelationCreate();
    logger.setActionId(request.action);
    logger.setRequestId();
    logger.logInformation(request.action + "() called.");
  }

  const correlationId: string = logger.getRequestId();

  // preparing the headers
  const headers: Headers = new Headers();
  headers.append('Authorization', `Bearer ${accessToken.token}`);
  if (!isFormdata) {
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
  }
  headers.append('sessionId', teamsContext?.teamcenter?.session?.sessionId || '');
  headers.append('userId', teamsContext?.teamcenter?.session?.userId || '');
  headers.append('x-siemens-session-id', logger.getSessionId());
  headers.append('x-siemens-operation-id', logger.getActionId());
  headers.append('x-correlation-id', correlationId);

  if (request.headers) {
    request.headers.forEach((header: string[]) => {
      headers.append(header[0], header[1]);
    });
  }

  const requestInit: RequestInit = {
    method: request.method ?? 'GET',
    headers: headers,
  };

  if (request.method && (request.method === 'POST' || request.method === 'PUT')) {
    if (isFormdata) {
      requestInit.body = obj;
    } else {
      requestInit.body = JSON.stringify(obj);
    }
  }

  const response: Response =  await fetch(request.url, requestInit);

  if (request.action && response) {
    logger.logInformation(request.action + "() completed.");
    logger.clearActionId();
    logger.clearRequestId();
  }

  if (!response || response.status < 200 || response.status > 204) {
    const errorMessage: ErrorMessage = await handleTcTeamsAPIError (request.url, correlationId, response);
    teamsContext?.failure.setError(errorMessage);
    return  {
      response: response,
      error: errorMessage
    };
  }

  return await getResponseUsingResponseType(request.returnType, response);
};
