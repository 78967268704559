import { logger } from "../Logger";
import { Constants } from "../components/common/Constants";
import config from "../config/config";
import { ApiRequest } from "../types";
import { ApiRequestReturnType, ApiResponse, MethodType } from "../types/ApiRequest";
import { getResponseUsingResponseType, handleTcTeamsAPIError } from "./utility";

/**@class: RequestUtils
 * @description: Module having the wrapper request APIs for executing the post and get requests.
 */
export class RequestUtils {
  /**@function callTcTeamsApi
   * @ method to call TeamsTcAPI from frontend.
   * @param operationName {string} operation to be performed
   * @param teamsUserCredential {any} teams user credential
   * @param teamcenterSession {any} teamcenter session information, optional for non-tcbroker calls
   * @param reqBody {any} request body for the POST, PUT API calls.
   * @returns {ApiResponse} response from the TcTeamsApi.
   */
  public static async callTcTeamsApi(operationName: string, teamsUserCredential: any, teamcenterSession: any, reqBody: any = undefined): Promise<ApiResponse> {
    logger.logTrace(`Entered ${this.callTcTeamsApi.name}`);
    const apiResponse: ApiResponse = {
      error: undefined,
      data: undefined
    };

    logger.logCorrelationCreate();
    logger.setActionId('configuration');
    logger.setRequestId();
    const correlationId: string = logger.getRequestId();

    const accessToken: any = await teamsUserCredential?.getToken('');
    const isFormData = reqBody instanceof FormData;
    const headers: Headers = this.getPopulatedRequestHeaders(accessToken.token, correlationId, teamcenterSession, isFormData);
    const request: ApiRequest | undefined = RequestUtils.getAPIRequestForOperation(operationName);
    if (request) {
      logger.logInformation(request.action + "() called.");
      const requestInit: RequestInit = {
        method: request.method ?? 'GET',
        headers: headers,
      };

      if (request.method && (request.method === 'POST' || request.method === 'PUT')) {
        requestInit.body = JSON.stringify(reqBody);
      }

      const response: Response =  await fetch(request.url, requestInit);
      if (request.action && response) {
        logger.logInformation(request.action + "() completed.");
        logger.clearActionId();
        logger.clearRequestId();
      }
      if (!response || response.status < 200 || response.status > 204) {
        apiResponse.error = await handleTcTeamsAPIError(request.url, correlationId, response);
      }
      
      const responseData: any = await getResponseUsingResponseType(request.returnType, response);
      if (responseData) {
        apiResponse.data = responseData
      } else {
        apiResponse.error = {
          title: "Internal Client Error",
          message: "An internal error has occured on client side.",
          correlationId: correlationId,
          statusCode: 450
        }
      }
    } else {
      logger.logError(`Failed to create the ApiRequest for operation: ${operationName}`);
    }
    logger.logTrace(`Exit ${this.callTcTeamsApi.name}`);
    return apiResponse;
  }

  /**
   * @function getAPIRequestForOperation
   * @description method to get the API request for the given operation.
   * @param operationName {string} operation name.
   * @returns {ApiRequest}
   */
  public static getAPIRequestForOperation(operationName: string): ApiRequest | undefined {
    let retVal: ApiRequest | undefined;
    switch (operationName) {
      case Constants.opConfiguration:
      case Constants.opUserConfiguration:
      case Constants.opGetSessionanalyticsInfo:
      case Constants.opgetBaseChangeTypes:
        retVal = {
          url: `${config.tcTeamsWebApiUrl}/${operationName}`,
          method: MethodType.GET,
          key: operationName,
          action: operationName,
        };
        break;
      case Constants.oploginSSO:
      case Constants.getPreference:
      case Constants.getTypeConstantValues:
      case Constants.addParticipant:
      case Constants.removeParticipant:
      case Constants.listObjects:
      case Constants.getDeclarativeStyleSheets:
      case Constants.opGetFeedback:
      case Constants.getTaskResults:
        retVal = {
          url: `${config.tcTeamsWebApiUrl}/${operationName}`,
          method: MethodType.POST,
          key: operationName,
          action: operationName,
          returnType: ApiRequestReturnType.json
        };
        break;
      default:
        retVal = undefined;
        break;
    }
    return retVal;
  }

  /**@function getPopulatedRequestHeaders
   * @description method to create the request headers.
   * @param bearerToken {any} access token used in Authorization header.
   * @param correlationId {string} correlation id for the logging/debugging purpose.
   * @param ifFormData {boolean} identifies the request body has Form data or not.
   * @param tcContext {any} teamcenter context if present. This can be undefined for non Teamcenter operations.
   * @returns {Headers}
   */
  public static getPopulatedRequestHeaders(bearerToken: any, correlationId: string, tcContext: any, ifFormData: boolean): Headers {
    logger.logTrace(`Entered ${this.getPopulatedRequestHeaders.name}`);
    const retHeaders = new Headers();
    retHeaders.append('Authorization', `Bearer ${bearerToken}`);
    retHeaders.append('sessionId', tcContext?.sessionId || '');
    retHeaders.append('userId', tcContext?.userId || '');
    retHeaders.append('userUid', tcContext?.userUid || '');
    retHeaders.append('x-siemens-session-id', logger.getSessionId());
    retHeaders.append('x-siemens-operation-id', logger.getActionId());
    retHeaders.append('x-correlation-id', correlationId);
    if (!ifFormData) {
      retHeaders.append('Content-Type', 'application/json');
      retHeaders.append('Accept', 'application/json');
    }
    logger.logTrace(`Exit ${this.getPopulatedRequestHeaders.name}`);
    return retHeaders;
  }
}
