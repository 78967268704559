import {v4 as uuidv4} from 'uuid';
//import {TelemetryClient} from 'applicationinsights'
//import appInsights = require("applicationinsights");
import {ITeamsTeamcenterLoggerSeverityLevel, ITeamsTeamcenterLoggerSeverityLevelType, getTeamsTeamcenterLoggerSeverityLevelEnum} from './ITeamsTeamcenterLoggerSeverityLevel'
import {ITeamsTeamcenterLogger} from './ITeamsTeamcenterLogger'
import {ITeamsTeamcenterLoggerConfiguration} from './ITeamsTeamcenterLoggerConfiguration'
import {TeamsTeamcenterLoggerConfiguration} from './TeamsTeamcenterLoggerConfiguration'
import { ApplicationInsights, SeverityLevel, IEventTelemetry, IMetricTelemetry, ICustomProperties } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
//import * as fs from 'fs';
//import { SeverityLevel } from 'applicationinsights/out/Declarations/Contracts';
//const path = require('path');
/// <summary>
/// Class TeamsTeamcenterLogger based on interface ITeamsTeamcenterLogger
/// </summary>
export class TeamsTeamcenterLogger implements ITeamsTeamcenterLogger{
    private _name: string = "";
    private _tenantId: string = "";
    private _sessionId: string = "";
    private _actionId: string = "";
    private _requestId: string = "";
    private _telemetryClient: ApplicationInsights|null = null;

    private readonly  ENV_SESSION_ID: string = "SIEMENS_SESSION_ID";
    private readonly ENV_OPERATION_ID: string = "SIEMENS_OPERATION_ID";
    private readonly ENV_CORRELATION_ID: string = "LOG_CORRELATION_ID";

    private _config: ITeamsTeamcenterLoggerConfiguration;
    /// <summary>
    /// TeamsTeamcenterLogger constructor 
    /// </summary>
    /// <param name="classname">Logger name</param>
    /// <param name="config">Current logger configuration ITeamsTeamcenterLoggerConfiguration</param>
    /// <returns>void</returns>
    constructor(classname: any|string|null, config: ITeamsTeamcenterLoggerConfiguration | null)
    {
        this._name = "UNKNOWN";
        if(classname != null)
        {
            if(typeof(classname) == "string")
                this._name = classname;
            else
                this._name = (<any>classname).constructor.name;
        }
        this._tenantId = "";
        this._sessionId = "";
        this._actionId = "";
        this._requestId = "";
        if(config == null)
            this._config = new TeamsTeamcenterLoggerConfiguration();
        else 
            this._config = config;

       /* if((this.getCurrentConfig().logApplicationInsightsConnectionString != null)&&(this.getCurrentConfig().logApplicationInsightsConnectionString.trim().length>0))    
            this._telemetryClient = new TelemetryClient(this.getCurrentConfig().logApplicationInsightsConnectionString);
        else
            this._telemetryClient = null;
            */
        if((this.getCurrentConfig().logApplicationInsightsConnectionString != null)&&(this.getCurrentConfig().logApplicationInsightsConnectionString.trim().length>0))    
        {             
            var reactPlugin = new ReactPlugin();
            // Add the Click Analytics plug-in.
            /* var clickPluginInstance = new ClickAnalyticsPlugin();
               var clickPluginConfig = {
                 autoCapture: true
            }; */
            this._telemetryClient = new ApplicationInsights({
                config: {
                    connectionString: this.getCurrentConfig().logApplicationInsightsConnectionString,
                    // If you're adding the Click Analytics plug-in, delete the next line.
                    extensions: [reactPlugin],
                 // Add the Click Analytics plug-in.
                 // extensions: [reactPlugin, clickPluginInstance],
                    extensionConfig: {
                   //   [reactPlugin.identifier]: { history: browserHistory }
                   // Add the Click Analytics plug-in.
                   // [clickPluginInstance.identifier]: clickPluginConfig
                    },
                    enableAutoRouteTracking: true,
                    disableAjaxTracking: false,
                    autoTrackPageVisitTime: true,
                    enableCorsCorrelation: true,
                    enableRequestHeaderTracking: true,
                    enableResponseHeaderTracking: true
                }
            });
            this._telemetryClient.loadAppInsights();   
        }
        else
            this._telemetryClient = null;

    }
    /// <summary>
    /// log method per severity level
    /// </summary>
    /// <param name="message">message to be logged</param>
    /// <returns>void</returns>      
    logDebug(message: string): void
    {
        this.log(ITeamsTeamcenterLoggerSeverityLevel.Debug,message);
    }
    logInformation(message: string): void
    {
        this.log(ITeamsTeamcenterLoggerSeverityLevel.Information,message);
    }
    logWarning(message: string): void
    {
        this.log(ITeamsTeamcenterLoggerSeverityLevel.Warning,message);
    }
    logTrace(message: string): void
    {
        this.log(ITeamsTeamcenterLoggerSeverityLevel.Trace,message);
    }
    logError(message: string): void
    {
        this.log(ITeamsTeamcenterLoggerSeverityLevel.Error,message);
    }
    logCritical(message: string): void
    {
        this.log(ITeamsTeamcenterLoggerSeverityLevel.Critical,message);
    }

    /// <summary>
    /// logAggregateMetric method defined in ITeamsTeamcenterLogger interface
    /// used to log an aggregate metric 
    /// </summary>
    /// <param name="metricname">metric's name</param>
    /// <param name="value">metric's value</param>
    /// <param name="properties">Dictionary string, string containing the metric's properties</param>
    /// <returns>bool</returns>    
    logAggregateMetric(metricname: string, value: number, properties?: {[key: string]: any;}): void
    {        
        var ev:IMetricTelemetry = {name: metricname, average: value, properties:         [
        ]};  
        var pr:ICustomProperties = [
            {ActionId:   this.getActionId()},
            {SessionId:  this.getSessionId()},
            {RequestId:  this.getRequestId()},
            {TenantId:   this.getTenantId() }            
        ]

        if(properties != null)
            for (let key in properties) {
                if(pr != null)
                    pr[key] = properties[key];
            }

        this._telemetryClient?.trackMetric(ev, pr);
        this._telemetryClient?.flush();  

    }
    /// <summary>
    /// logMetric method defined in ITeamsTeamcenterLogger interface
    /// used to log an aggregate metric 
    /// </summary>
    /// <param name="name">metric's name</param>
    /// <param name="values">Dictionarystring, double  metric's dictionary values</param>
    /// <param name="properties">Dictionary string, string containing the metric's properties</param>
    /// <returns>bool</returns>     
    logMetric(name: string, values: {[key: string]: number;}, properties?: {[key: string]: any;}): void
    {
        var ev:IEventTelemetry = {name: name, measurements: {} , properties:         [
        ]};        


        if(properties != null)
            for (let key in properties) {
                if(ev.properties != null)
                    ev.properties[key] = properties[key];
            }

        if(values != null)
            for (let key in values) {
                if(ev.measurements != null)
                    ev.measurements[key] = values[key];
            }
        var pr:ICustomProperties = [
            {ActionId:   this.getActionId()},
            {SessionId:  this.getSessionId()},
            {RequestId:  this.getRequestId()},
            {TenantId:   this.getTenantId() }            
        ]

        this._telemetryClient?.trackEvent(ev,pr);
        this._telemetryClient?.flush();        
    }
    getConsoleType(level: ITeamsTeamcenterLoggerSeverityLevelType): "debug"|"info"|"warn"|"error"
    {
        if( level == ITeamsTeamcenterLoggerSeverityLevel.Debug)
            return "debug";
        if( level == ITeamsTeamcenterLoggerSeverityLevel.Trace)
            return "debug";
        if( level == ITeamsTeamcenterLoggerSeverityLevel.Information)
            return "info";
        if( level == ITeamsTeamcenterLoggerSeverityLevel.Warning)
            return "warn";
        if( level == ITeamsTeamcenterLoggerSeverityLevel.Error)
            return "error";
        if( level == ITeamsTeamcenterLoggerSeverityLevel.Critical)
            return "error";
        return "info";
    }
    private getTeamsTeamcenterLevel(level: ITeamsTeamcenterLoggerSeverityLevelType): string
    {
        switch (level)
        {
            case ITeamsTeamcenterLoggerSeverityLevel.Debug: return "DEBUG";
            case ITeamsTeamcenterLoggerSeverityLevel.Information: return "INFO";
            case ITeamsTeamcenterLoggerSeverityLevel.Warning: return "WARN";
            case ITeamsTeamcenterLoggerSeverityLevel.Trace: return "TRACE";
            case ITeamsTeamcenterLoggerSeverityLevel.Error: return "ERROR";
            case ITeamsTeamcenterLoggerSeverityLevel.Critical: return "FATAL";
            default:
                return "UNKNOWN";
        }
    }
    getCurrentConfig():ITeamsTeamcenterLoggerConfiguration
    {
        return this._config;
    }

    isEnabled(logLevel: ITeamsTeamcenterLoggerSeverityLevelType): boolean {
        let result:boolean = logLevel != ITeamsTeamcenterLoggerSeverityLevel.None
            && this.getCurrentConfig().logLevel != ITeamsTeamcenterLoggerSeverityLevel.None
            && getTeamsTeamcenterLoggerSeverityLevelEnum(logLevel) >= getTeamsTeamcenterLoggerSeverityLevelEnum(this.getCurrentConfig().logLevel);

        return result;
    }

    private  getAppInsightsLevel(level: ITeamsTeamcenterLoggerSeverityLevelType):SeverityLevel
    {
        switch (level)
        {
            case ITeamsTeamcenterLoggerSeverityLevel.Debug: return SeverityLevel.Verbose;
            case ITeamsTeamcenterLoggerSeverityLevel.Information: return SeverityLevel.Information;
            case ITeamsTeamcenterLoggerSeverityLevel.Warning: return SeverityLevel.Warning;
            case ITeamsTeamcenterLoggerSeverityLevel.Trace: return SeverityLevel.Verbose;
            case ITeamsTeamcenterLoggerSeverityLevel.Error: return SeverityLevel.Error;
            case ITeamsTeamcenterLoggerSeverityLevel.Critical: return SeverityLevel.Critical;
            default:
                return SeverityLevel.Information;
        }
    } 
      
    log(level: ITeamsTeamcenterLoggerSeverityLevelType, message: string): void
    {
        if(this.isEnabled(level))
        {
            var d = new Date();
            var sDate =  d.toISOString().replace('-','/').replace('-','/').replace('T','-').replace('Z',' UTC');
            var slevel = this.getTeamsTeamcenterLevel(level);
            let Msg:string = sDate + " - " + slevel + " - " + this._name 
            + " - " + this._tenantId + " - " + this._sessionId + " - " + this._actionId + " - " + this._requestId + " - " + message;

            if(this.getCurrentConfig().logConsole==true){
                if(this.getConsoleType(level) == "debug")
                    console.log(Msg);
                else
                    console[this.getConsoleType(level)](Msg);
            }

            if((this.getCurrentConfig().logApplicationInsightsConnectionString != null) &&
               (this.getCurrentConfig().logApplicationInsightsConnectionString.trim().length>0))
            {
                this._telemetryClient?.trackTrace({message: Msg, severityLevel: this.getAppInsightsLevel(level) });
                this._telemetryClient?.flush();
            }                           
        }
    }
    /// <summary>
    /// logCorrelationCreate method defined in ITeamsTeamcenterLogger interface
    /// used to manage correlation ids. This method create a new session Id which will 
    /// be associated with the current session, it will clear action id and request id as well  
    /// </summary>
    /// <returns>void</returns>     
    logCorrelationCreate(): void
    {
        this.setSessionId(uuidv4().replace('-','').replace('-','').replace('-','').replace('-',''));
        this.clearRequestId();
        this.clearActionId();
    }
    /// <summary>
    /// logCorrelationCreateFromParent method defined in ITeamsTeamcenterLogger interface
    /// used to manage correlation ids. This method reads the environment variables   
    /// "SIEMENS_SESSION_ID", "SIEMENS_OPERATION_ID", "LOG_CORRELATION_ID" to initialize 
    /// respectively the attributes SessionId, ActionId and RequestId. 
    /// </summary>
    /// <returns>void</returns>     
    logCorrelationCreateFromParent(): void
    {
        let operationId: string | undefined = process.env.SIEMENS_OPERATION_ID;
        if ((operationId !== null)&&(typeof operationId != 'undefined')&&(operationId.trim().length>0))
            this.setActionId(operationId);
        else
            this.clearActionId();

        let sessionId: string | undefined = process.env.SIEMENS_SESSION_ID;
        if ((sessionId !== null)&&(typeof sessionId != 'undefined')&&(sessionId.trim().length>0))
            this.setSessionId(sessionId);
        else
            this.clearSessionId();

        let correlationId: string | undefined = process.env.LOG_CORRELATION_ID;
        if ((correlationId !== null)&&(typeof correlationId != 'undefined')&&(correlationId.trim().length>0))
            this.setRequestId(correlationId);
        else
            this.clearRequestId();        
    }
    /// <summary>
    /// getTenantId method defined in ITeamsTeamcenterLogger interface
    /// used to return current Tenant Id associated with the current context. 
    /// </summary>
    /// <returns>string</returns>     
    getTenantId(): string
    {
        return this._tenantId;
    }
    /// <summary>
    /// setTenantId LogMetric method defined in ITeamsTeamcenterLogger interface
    /// used to set the current Tenant Id 
    /// </summary>
    /// <param name="tenant">Tenant id</param>
    /// <returns>void</returns>
    setTenantId(tenant: string): void
    {
        this._tenantId = tenant;
    }
    /// <summary>
    /// clearTenantId method defined in ITeamsTeamcenterLogger interface
    /// used to clear current Tenant Id. 
    /// </summary>
    /// <returns>void</returns>    
    clearTenantId(): void
    {
        this._tenantId = "";
    }
    /// <summary>
    /// getSessionId method defined in ITeamsTeamcenterLogger interface
    /// used to return current Session Id associated with the current context. 
    /// </summary>
    /// <returns>string</returns>     
    getSessionId(): string
    {
        return this._sessionId;
    }
    /// <summary>
    /// setSessionId method defined in ITeamsTeamcenterLogger interface
    /// used to set the current Session Id. If the argument is null, a new 
    /// Guid will be created to set the session Id 
    /// </summary>
    /// <param name="sessionId">session id</param>
    /// <returns>void</returns>    
    setSessionId(sessionId?: string): void 
    {
        if((sessionId != null) &&
        (sessionId.trim().length>0))
            this._sessionId = sessionId;
        else
            this._sessionId = uuidv4().replace('-','').replace('-','').replace('-','').replace('-','');
    };
    /// <summary>
    /// clearSessionId method defined in ITeamsTeamcenterLogger interface
    /// used to clear current Session Id. 
    /// </summary>
    /// <returns>void</returns> 
    clearSessionId(): void 
    {
        this._sessionId = "";
    };    
    /// <summary>
    /// getActionId method defined in ITeamsTeamcenterLogger interface
    /// used to return current Action Id associated with the current context. 
    /// </summary>
    /// <returns>string</returns>    
    getActionId(): string
    {
        return this._actionId; 
    }
    /// <summary>
    /// setActionId method defined in ITeamsTeamcenterLogger interface
    /// used to set the current Action Id. 
    /// </summary>
    /// <param name="action">action id</param>
    /// <returns>void</returns>    
    setActionId(action: string): void
    {
        this._actionId = action;
    }
    /// <summary>
    /// CcearActionId method defined in ITeamsTeamcenterLogger interface
    /// used to clear current Action Id. 
    /// </summary>
    /// <returns>void</returns>    
    clearActionId(): void
    {
        this._actionId = "";
    }
    /// <summary>
    /// getRequestId method defined in ITeamsTeamcenterLogger interface
    /// used to return current Request Id associated with the current context. 
    /// </summary>
    /// <returns>string</returns>     
    getRequestId(): string
    {
        return this._requestId;
    }
    /// <summary>
    /// setRequestId method defined in ITeamsTeamcenterLogger interface
    /// used to set the current Request Id. 
    /// </summary>
    /// <param name="requestId">request id</param>
    /// <returns>void</returns>    
    setRequestId(requestId?: string): void
    {
        if((requestId != null) &&
        (requestId.trim().length>0))
            this._requestId = requestId;
        else
            this._requestId = uuidv4().replace('-','').replace('-','').replace('-','').replace('-','');
    }
    /// <summary>
    /// clearRequestId method defined in ITeamsTeamcenterLogger interface
    /// used to clear current Request Id. 
    /// </summary>
    /// <returns>void</returns>     
    clearRequestId(): void
    {
        this._requestId = "";
    }

}