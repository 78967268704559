import siemensLogo from "../../Images/siemens_logo.png";
import transformationIcon from "../../Images/transformation_symbol.png";

const TabHeader: React.FunctionComponent = () => {
  return (
    <div className="banner">
      <div className="column-left">
        <div className="logo-left" style={{ backgroundImage: `url(${transformationIcon})` }}></div>
      </div>
      <div className="column-right">
        <div className="logo-right" style={{ backgroundImage: `url(${siemensLogo})` }}></div>
      </div>
    </div>
  );
};

export default TabHeader;
