import { useState,useEffect, useRef } from 'react';
import { Button, Radio, RadioGroup, Popover, PopoverSurface, PopoverTrigger } from '@fluentui/react-components';
import { ArrowSort24Regular } from '@fluentui/react-icons';

interface SortToolbarProps {
  sortStatus?: string;
  sortOptions?: { [key: string]: { value: string; label: string } };
  onSortStatusChanged?: (sortStatus: string) => void;
  disabled: boolean;
}

const SortToolbar: React.FunctionComponent<SortToolbarProps> = ({ sortStatus, sortOptions, onSortStatusChanged, disabled }: SortToolbarProps) => {

  const [showPopover, setShowPopover] = useState<boolean>(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShowPopover(false); // Call onClose function when clicked outside of Popover
      }
    };
    if (showPopover) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopover]);

  return (
    <Popover inline={true} open={showPopover}>
      <PopoverTrigger disableButtonEnhancement>
        <Button id="Sort" appearance="transparent" disabled={disabled} icon={<ArrowSort24Regular fontSize={24} onClick={() => setShowPopover(true)} />}></Button>
      </PopoverTrigger>
      <PopoverSurface className="sort-popover" ref={popoverRef}>
        <RadioGroup
          defaultValue={sortStatus}
          onChange={(_event, data) => {
            setShowPopover(false);
            if (onSortStatusChanged) {
              onSortStatusChanged((data.value as string) ?? '');
            }
          }}
        >
          {sortOptions &&
            Object.keys(sortOptions).map((key, index) => {
              return <Radio key={index} value={sortOptions[key].value} label={sortOptions[key].label} />;
            })}
        </RadioGroup>
      </PopoverSurface>
    </Popover>
  );
};

export default SortToolbar;
