import React from 'react';
import { DatePicker, TimePicker, IComboBox ,ITimeRange, initializeIcons, TimePickerValidationResultData, ITimePickerStrings } from "@fluentui/react";

interface DateTimeProps {
  currentDate: Date | null;
  onDateChange: (date: Date, id:string) => void;
  errorExists: (isError: boolean) => void;
  id: string;
  isTimeEnabled: boolean | undefined;
}

const DateTime: React.FC<DateTimeProps> = ({ currentDate, onDateChange, errorExists, id, isTimeEnabled }) => {

  const isTimePickerErrorExists = React.useRef(false);
  const [selectedDate, setSelectedDate] = React.useState< Date | null | undefined>( null);
  const [selectedTime, setSelectedTime] = React.useState<Date | null>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const timeRange: ITimeRange = {
    start: 0,
    end: 24,
  };

  const timePickerStrings: ITimePickerStrings = {
    invalidInputErrorMessage: "Invalid time format. Please use the 24-hour format HH:MM."
  };

  React.useEffect(() => {
    if(currentDate){
      setSelectedDate(currentDate);
      setSelectedTime(currentDate);
    }
    else{
      //set default time as 00:00
      const newDate = new Date();
      newDate.setHours(0, 0, 0, 0);
      setSelectedTime(newDate);
    }
    initializeIcons(); // Method to load icons
    setLoaded(true); // Set loaded to true after setting the date and time
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  const handleDateChange = (data: Date | null | undefined) => {
    if(isValidDate(data)){
      const newDate = data ? new Date(data) : new Date();
      if( selectedTime instanceof Date && !isNaN(selectedTime.getTime())) {
        const hours = selectedTime.getHours();
        const minutes = selectedTime.getMinutes();
        newDate.setHours(hours);
        newDate.setMinutes(minutes);
      }
      setSelectedDate(newDate);
      setSelectedTime(newDate);//keep selectedTime in sync with date
      onDateChange(newDate, id);
    }
  };

  const isValidDate = (date: Date | null | undefined): boolean => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  

  const handleTimeChange = (_ev: any, newTime: Date) => {
    if(isValidDate(newTime)) {
      if(selectedDate === null || selectedDate === undefined) {
        setSelectedDate(newTime);//set the date to the selected time if date is not selected
      }
      setSelectedTime(newTime);
      onDateChange(newTime, id);
    }
  };

  const onValidationResult = (_ev: React.FormEvent<IComboBox>, timePickerValidationResultData: TimePickerValidationResultData) => {
    if (timePickerValidationResultData.errorMessage !== undefined) {
      const isErrorMessage = timePickerValidationResultData.errorMessage.length > 0;

      errorExists(isErrorMessage);
      isTimePickerErrorExists.current = isErrorMessage;

    }
  };

  const renderDateTimeWidget = () => {
    return (
      <>
        <div className={isTimeEnabled ? 'half-width' : 'full-width'}>
          <DatePicker
            placeholder={'Select a date...'}
            value={selectedDate || undefined}
            onSelectDate={handleDateChange}
          />
        </div>
        {isTimeEnabled && (
          <div className="time-picker-container">
            <TimePicker
              placeholder={'Select a time...'}
              strings={timePickerStrings}
              value={selectedTime || undefined}
              onChange={handleTimeChange}
              dateAnchor={selectedTime || undefined}
              timeRange={timeRange}
              onValidationResult={onValidationResult}
            />
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <div className="date-time-container">
        { loaded && renderDateTimeWidget() }
      </div>
    </>
  );
};

export default DateTime;
