import config from "../config/config";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { useApiMutation } from "./useApiMutation";
import { logger } from "../Logger";
import { ValidateLOVSelectionValues } from "../components/common/TcSOATypes";
import { MethodType } from "../types/ApiRequest";

/**
 * Validate the LOV selection for given business object at the backend.
 */
export const useValidateLovValueSelections = () => {
  const teamsContext = useContext(TeamsFxContext);

  const mutation = useApiMutation<ValidateLOVSelectionValues, any>({
    url: `${config.tcTeamsWebApiUrl}/validateLovValueSelections`,
    method: MethodType.POST,
    key: 'validateLovValueSelections',
    enabled: teamsContext.teamcenter.isAuthenticated && !teamsContext.teamcenter.isAuthenticating,
    action: 'useValidateLovValueSelections'
  }, logger);

  return mutation;
};
