import config from "../config/config";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { useApiMutation } from './useApiMutation';
import { logger } from "../Logger";
import { ApiRequestReturnType } from "../types";
import { MethodType } from "../types/ApiRequest";

/**
 * Update Data Privacy configuration for the login user using the TcTeamsApi that is protected by Azure AD and Teamcenter session.
 */
export const useUpdateUserPrivacy = () => {
  const teamsContext = useContext(TeamsFxContext);
  const mutation = useApiMutation<any, any>({
    url: `${config.tcTeamsWebApiUrl}/convreference/updateUserConsent`,
    method: MethodType.POST,
    key: "UserPrivacy",
    enabled: teamsContext?.teamcenter.isAuthenticated && !teamsContext?.teamcenter.isAuthenticating,
    action: 'useUpdateUserPrivacy',
    returnType: ApiRequestReturnType.json,
    headers: [["userUId", teamsContext?.teamcenter?.session?.userUid!]]
  }, logger);

  return mutation;
};
