import React from "react";
import { logger } from "../../Logger";
import { Radio, RadioGroup } from "@fluentui/react-components";
import { Constants } from "../common/Constants";

interface ConfigurationProps {
  configuration: any,
  updateDataPrivacy: (key: string, val: boolean) => void;
};

const DigitalProductExcellence: React.FunctionComponent<ConfigurationProps> = ({configuration, updateDataPrivacy}) => {
  const getUserTelemetryDefaultValue = (consentKey: string) => {
    logger.logTrace(`Entered ${getUserTelemetryDefaultValue.name}`);
    let retVal: string = "";
    if (configuration) {
      if (consentKey in configuration) {
        const userTelemetryOptIn: any = configuration[consentKey];
        if (userTelemetryOptIn !== null) {
          if (userTelemetryOptIn === true) {
            retVal = Constants.accept;
          } else {
            retVal = Constants.decline;
          }
        } else {
          retVal = Constants.accept;
        }
      }
    }
    logger.logTrace(`Exit ${getUserTelemetryDefaultValue.name}`);
    return retVal;
  }
  
  return (
    <>
      <div id="dpe_consent" className="data-privacy-consent-section">
        <div>
          <h3> {Constants.digitalProductExcellence} </h3>
          <p> {Constants.depPara_1} </p>
          <p>
            {Constants.depPara_2} {" "}
            <b>
              <a
                href="https://www.sw.siemens.com/en-US/trust-center/digital-product-experience/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {Constants.siemensTrustCenter}
              </a>
            </b>
          </p>
          <p> {Constants.depChangeConsent} </p>
          <div>
            <RadioGroup
              layout="horizontal"
              defaultValue={getUserTelemetryDefaultValue(
                Constants.userDigitalProductTelemetryOptIn
              )}
            >
              <Radio
                id="rad_dpe_accept"
                value="accept"
                label="Agree"
                onChange={() =>
                  updateDataPrivacy(Constants.userDigitalProductTelemetryOptIn, true)
                }
              />
              <Radio
                id="rad_dpe_decline"
                value="decline"
                label="Decline"
                onChange={() =>
                  updateDataPrivacy(Constants.userDigitalProductTelemetryOptIn, false)
                }
              />
            </RadioGroup>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalProductExcellence;
