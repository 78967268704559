// import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./css/common/style.css";
import "./css/authentication/auth.css";
import "./css/problems/style.css";
import "./css/workflows/style.css";
import "./css/common/tabHeader.css"
import "./css/common/toolbar.css";
import "./css/authentication/error.css";
import "./css/dataprivacy.css";
import "./css/common/datePicker.css";
import App from "./components/App";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);
