import config from "../config/config";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { useApiMutation } from "./useApiMutation";
import { logger } from "../Logger";
import { IGetDeclarativeStyleSheetsRequestData } from "../components/common/TcSOATypes";
import { MethodType } from "../types/ApiRequest";
import { Constants } from "../components/common/Constants";

/**
 * Get the declarative view model json for the create request for given business object.
 */
export const useGetDeclarativeStyleSheet = () => {
  const teamsContext = useContext(TeamsFxContext);
  const response = useApiMutation<IGetDeclarativeStyleSheetsRequestData, any>({
    url: `${config.tcTeamsWebApiUrl}/${Constants.getDeclarativeStyleSheets}`,
    method: MethodType.POST,
    key: Constants.getDeclarativeStyleSheets,
    enabled: teamsContext.teamcenter.isAuthenticated && !teamsContext.teamcenter.isAuthenticating,
    action: 'useGetDeclarativeStyleSheet'
  }, logger);

  return response;
};
