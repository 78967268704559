import config from "../config/config";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { useApiMutation } from './useApiMutation';
import { logger } from "../Logger";
import { MethodType } from "../types/ApiRequest";

/**
 * Create problem from the /createtcobject endpoint from TcTeamsApi that is protected by Azure AD and Teamcenter session
 */
export const useCreateProblem = () => {
  const teamsContext = useContext(TeamsFxContext);

  const mutation = useApiMutation<FormData>({
    url: `${config.tcTeamsWebApiUrl}/createtcobject`,
    method: MethodType.POST,
    key: "CREATE",
    enabled: teamsContext.teamcenter.isAuthenticated && !teamsContext.teamcenter.isAuthenticating,
    action: 'useCreateProblem'
  }, logger);

  return mutation;
};
