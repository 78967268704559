import { logger } from "../Logger";
import config from "../config/config";
import { UseTypeQueryResult } from '../types';
import { MethodType } from "../types/ApiRequest";
import { useApi } from './useApi';

export const useSearchTCObjects = (name: string, maxReturn = 50): UseTypeQueryResult<{ getPRItemsResponse: any}> => {
  const response = useApi({
    url: `${config.tcTeamsWebApiUrl}/items/${name}/${maxReturn}`,
    method: MethodType.GET,
    key: ['search-tc-objects', name],
    enabled: !!name,
    action: 'useSearchTCObjects',
  }, logger);

  return {
    get getPRItemsResponse() {
      return response?.data;
    },
    ...response,
  };
};
