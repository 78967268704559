import config from "../config/config";
import { useApi } from './useApi';
import { ApiRequestReturnType, UseTypeQueryResult } from '../types';
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { logger } from '../Logger';
import { MethodType } from "../types/ApiRequest";

/**
 * Get a list of problems from the /pr endpoint that is protected by Azure AD and Teamcenter session
 *
 */
export const useGetImage = (problemId?: string, imageTicket?: string): UseTypeQueryResult<{ image: Blob }> => {
  const teamsContext = useContext(TeamsFxContext);

  const response = useApi({
    url: `${config.tcTeamsWebApiUrl}/image?pr=${problemId}`,
    method: MethodType.GET,
    key: ['images', problemId, imageTicket],
    headers: [['ImageTicket', imageTicket || '']],
    enabled: teamsContext.teamcenter.isAuthenticated && !teamsContext.teamcenter.isAuthenticating,
    returnType: ApiRequestReturnType.blob,
    action: 'useGetImage'
  }, logger);

  return {
    get image() {
      return response?.data;
    },
    ...response,
  };
};
